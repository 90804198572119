import axios from "axios";
import {getAuthTokenFromStorage} from "../utils";
import env from "../utils/env";


const axiosInstance = axios.create({
  baseURL: env.AXIOS_BASE_URL,
  timeout: 120000,
  transformResponse: [function (data, res) {
    if (res.getContentType() === "application/json") {
      return JSON.parse(data)
    }
    // if there is no data just return "data", this is for response code like 204 which does not return anything
    return data
  }],
})

axiosInstance.interceptors.request.use(function (config) {
  const token = getAuthTokenFromStorage()

  if(!!token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  
  return config
})

export default axiosInstance
