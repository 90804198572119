import {
  basicPlanPaymentMethods,
  desktopOfflinePaymentMethods,
  generalCheckoutPaymentMethods,
  posCheckoutPaymentMethods,
  purchaseOrderPaymentMethods,
  storeFrontPaymentMethods,
  storeFrontWithPayOnDeliveryPaymentMethods,
  tableCheckoutPaymentMethods
} from "../../../data/selectField/paymentMethodsList";
import {useUserProfile} from "../../useServices/userHooks";
import {isUserPackagePlanBasic} from "../../../utils/handleChecks";
import {isPremiumOrStarterPackage,} from "../../../utils/manageDisplayOfFeatures";
import {isStoreUsingNGN} from "../useNavigationMenuList";
import {useBusinessByIdQuery} from "../../useServices/useStoreServices";
import {useCustomPaymentMethodQuery} from "../../useServices/useCustomPaymentMethodServices";
import {useBusinessSubscriptionQuery} from "../../useServices/useBusinessSubscriptionServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {isAppStoreFront} from "../../../utils";
import {EmptyWallet} from "iconsax-react";
import {useSearchParams} from "react-router-dom";


const usePaymentMethods = () => {
  const [searchParams] = useSearchParams()
  const {globalState, isOffline} = useGlobalContext()
  const {data: userProfile} = useUserProfile()
  const {data: business} = useBusinessByIdQuery()
  const {data: customPaymentMethod} = useCustomPaymentMethodQuery()
  const {data: businessSubscriptions} = useBusinessSubscriptionQuery()
  
  
  const handlePOSCheckoutMethods = () => {
    if (isAppStoreFront()) {
      return business?.pay_on_delivery ? storeFrontWithPayOnDeliveryPaymentMethods : storeFrontPaymentMethods
    }
    
    if (isOffline) return desktopOfflinePaymentMethods
    
    if (isUserPackagePlanBasic(userProfile)) return basicPlanPaymentMethods
    if (!!globalState.tableId) return tableCheckoutPaymentMethods
    
    const newMethods = []
    posCheckoutPaymentMethods.forEach(method => {
      if (method.value === "credit" && !business?.settings?.credit_sales) return
      if (method.value === "partial" && !business?.settings?.partial_payment) return
      if (method.value === "customer_wallet" && (!isPremiumOrStarterPackage(userProfile) || !isStoreUsingNGN(business))) return
      if (method.value === "ovaloop_transfer" && !isStoreUsingNGN(business)) return
      if (method.value === "others" && customPaymentMethod?.count < 1) return
      if (method.value === "subscription" && businessSubscriptions?.length < 1) return
      
      newMethods.push(method)
    })
    
    return newMethods
  }
  
  const handleGeneralPaymentMethods = () => {
    if(isOffline) return desktopOfflinePaymentMethods
  
  
    if(isUserPackagePlanBasic(userProfile)) return basicPlanPaymentMethods
    if(!!globalState.tableId) return tableCheckoutPaymentMethods
    
    const newMethods = []
    posCheckoutPaymentMethods.forEach(method => {
      if (method.value === "credit" && !searchParams.get("invoice")) return
      if (method.value === "partial" && !business?.settings?.partial_payment) return
      if (method.value === "customer_wallet") return
      if (method.value === "ovaloop_transfer") return
      if (method.value === "others" && customPaymentMethod?.count < 1) return
      if (method.value === "subscription") return
  
      newMethods.push(method)
    })
    
    return newMethods
  }
  
  const handleCustomerSubscriptionMethods = () => {
    if(isOffline) return desktopOfflinePaymentMethods
  
    if(isUserPackagePlanBasic(userProfile)) return basicPlanPaymentMethods
  
    const newMethods = []
    generalCheckoutPaymentMethods.forEach(method => {
      if(method.value === "ovaloop_transfer" && !isStoreUsingNGN(business)) return
    
      newMethods.push(method)
    })
  
    return newMethods
  }
  
  
  const posPaymentMethods = handlePOSCheckoutMethods()
  const generalPaymentMethods = handleGeneralPaymentMethods()
  const customerSubscriptionPaymentMethods = handleCustomerSubscriptionMethods()
  return { posPaymentMethods, customerSubscriptionPaymentMethods, generalPaymentMethods, purchaseOrderPaymentMethods }
}



export const usePurchaseOrderPaymentMethods = (purchaseOrder = {}) => {
  if(!purchaseOrder?.id) {
    return purchaseOrderPaymentMethods
  }
  
  if(Number(purchaseOrder?.balance) === 0 && Number(purchaseOrder?.paid_amount) === 0) {
    return purchaseOrderPaymentMethods
  }
  
  if(purchaseOrder?.payment_mode === "credit") {
    return [
      { title: "Paid in full", value: "paid", Icon: EmptyWallet },
      { title: "Partial", value: "partial", Icon: EmptyWallet },
    ]
  }
  
  return [
    { title: "Partial", value: "partial", Icon: EmptyWallet },
  ]
}


export default usePaymentMethods


