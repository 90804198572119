import axios from "../../axios";
import {dateFilterType} from "../../data/selectField/orderFilter";
import {getOrderServiceStructure} from "./orderServiceStructure";
import handleQueryParams from "../../utils/handleQueryParams";


const getGroupOrderByBusinessIdService = async (businessId, searchValue = "", status, fromDateValue, toDateValue, customerValue, invoice, noPaginate) => {
	let url = `/group_orders/?business_id=${businessId}`

	if(!!status && !dateFilterType.includes(status) && status !== "customer") {
		url = url + `&status=${status}`
	}
	
	if(!!fromDateValue && !!toDateValue) {
		url = url + `&from_date=${fromDateValue}&to_date=${toDateValue}`
	}

	if(status === "Payment Date" && (!!fromDateValue && !!toDateValue)) {
		url = url + `&sort=payment`
	}

	if(!!customerValue) {
		url = url + `&customer_id=${customerValue}`
	}

	if(!!searchValue && searchValue !== " ") {
		url = url + `&group_id=${searchValue}`
	}
	
	url = url + `&invoice=${!!invoice}`
	
	if(!!noPaginate) {
		url = url + `&no_paginate=true`
	}

	try {
		const { data } = await axios.get(url)
		
		return getOrderServiceStructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const getGroupOrderWithDueDateByBusinessIdService = async ({ businessId, searchValue = " ", fromDateValue, toDateValue }) => {
	let url = `/group_orders/?business_id=${businessId}&sort=due`
	
	if(!!fromDateValue && !!toDateValue) {
		url = url + `&from_date=${fromDateValue}&to_date=${toDateValue}`
	}
	
	if(!!searchValue && searchValue !== " ") {
		url = url + `&group_id=${searchValue}`
	}
	
	try {
		const { data } = await axios.get(url)
		return getOrderServiceStructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getSingleGroupByBusinessIdAndGroupId = async (query = {}) => {
	try {
		const url = handleQueryParams('/orders', query)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const getKitchenGroupByBusinessIdAndGroupId = async (businessId) => {
	try {
		const { data } = await axios.get(`/orders/?business_id=${businessId}&kitchen_display=true&kitchen_status=pending`)

		const groupOrderIds = data?.results?.map(order => order.group_id)
		const ids = [...new Set(groupOrderIds)]

		 const results = ids.map(id => {
			const ordersForId = data?.results?.filter(order => order.group_id === id)
			return {
				group_id: id,
				orders: ordersForId
			}
		})

		return { ...data, results }
	} catch (e) {
		throw e?.response?.data || ""
	}
}


const getGroupOrderByBusinessIdAndTableTrackIdService = async (businessId, tableTrackId) => {
	try {
		const { data } = await axios.get(`/group_orders/?business_id=${businessId}&track_id=${tableTrackId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getGroupOrderByStaffIdService = async (queryParams) => {
	try {
		const url = handleQueryParams("group_orders/", queryParams)
		
		const { data } = await axios.get(url)
		return getOrderServiceStructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const payForGroupOrderService = async (body) => {
	try {
		const { data } = await axios.patch("/group_order_pay/", body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const partialPayForGroupOrderService = async (groupOrderId, body) => {
	try {
		const {data} = await axios.patch(`/partial_pay/${groupOrderId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const refundForGroupOrderService = async (body) => {
	try {
		const {data} = await axios.post(`/refund/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateKitchenGroupOrderService = async (businessId, groupOrderId, body) => {
	try {
		const {data} = await axios.patch(`/group_orders/${groupOrderId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const createOrderService = async (body) => {
	try {
		const { data } = await axios.post("/orders/", body)
		return data?.map(order => ({
			...order,
			customer: { id: order?.customer },
			banks: { id: order?.banks }
		}))
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const confirmGroupOrderByIdService = async (groupOrderId, values = {}) => {
	try {
		const {data} = await axios.patch(`/group_orders/${groupOrderId}/`, {
			status: "payment_confirmed",
			...values
		})
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
	
}

const cancelSingleGroupOrderByGroupIdService = async (businessId, groupOrderId) => {
	try {
		const { data } = await axios.patch(`/group_orders/${groupOrderId}/`, { status: "cancelled" })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const returnSingleGroupOrderByGroupIdService = async (businessId, groupOrderId) => {
	try {
		const { data } = await axios.patch(`/group_orders/${groupOrderId}/`, { status: "returned" })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const disburseOrderService = async (groupOrderId, body) => {
	try {
		const { data } = await axios.patch(`/group_orders/${groupOrderId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const returnOrderService = async (body) => {
	try {
		const { data } = await axios.post(`return_order/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const payInvoiceOrderService = async (groupOrderId, body) => {
	try {
		const { data } = await axios.patch(`/group_orders/${groupOrderId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const syncOfflineOrdersOnlineService = async (body) => {
	try {
		const { data } = await axios.post(`/sync_orders/`, body, {
			timeout: 999999999999999
		})
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export {
	getGroupOrderByBusinessIdService, getSingleGroupByBusinessIdAndGroupId, partialPayForGroupOrderService,
	getGroupOrderByBusinessIdAndTableTrackIdService, createOrderService, getGroupOrderByStaffIdService, returnSingleGroupOrderByGroupIdService,
	cancelSingleGroupOrderByGroupIdService, payForGroupOrderService, confirmGroupOrderByIdService, disburseOrderService
}