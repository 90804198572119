import {useBusinessSettingQuery} from "../../useServices/useBusinessSettingServices";
import {useActionPermissionFn} from "../../usePermissions/useActionPermission";
import {actionsPermissions} from "../../../data/permissions";
import {useUserProfile} from "../../useServices/userHooks";
import {onlyShowModulesBasedOnAccountsId, PRODUCTION_MODULE_ACCOUNTS} from "../../../utils/manageDisplayOfFeatures";


export const useReportTypeSelectField = () => {
  const permission = useActionPermissionFn()
  const businessSettings = useBusinessSettingQuery()
  
  const availableReportTypes = []
  
  // if (permission.check(actionsPermissions.allReport)) {
  //   availableReportTypes.push({name: "All", value: "all"})
  // }
  
  if (permission.check(actionsPermissions.salesDetailsReport)) {
    availableReportTypes.push({name: "Sales Details", value: "sales"})
  }
  
  if (permission.check(actionsPermissions.salesSummaryReport)) {
    availableReportTypes.push({name: "Sales Summary", value: "sales"})
  }
  
  if (permission.check(actionsPermissions.groupSalesReport)) {
    availableReportTypes.push({name: "Group Sales", value: "group_sales"})
  }
  
  if (permission.check(actionsPermissions.paymentReport)) {
    availableReportTypes.push({name: "End of Day Report", value: "payment_report"})
  }
  
  if (permission.check(actionsPermissions.productValuation)) {
    availableReportTypes.push({name: "Product Valuation", value: "valuation"})
  }
  
  if (permission.check(actionsPermissions.productTransferReport)) {
    availableReportTypes.push({name: "Product Transfer Report", value: "get_product_transfer_receive_history"})
  }
  
  if (permission.check(actionsPermissions.productTransferReport)) {
    availableReportTypes.push({name: "Product Receive Report", value: "get_product_transfer_receive_history"})
  }
  
  if (permission.check(actionsPermissions.expensesReport)) {
    availableReportTypes.push({name: "Expenses", value: "expenses"})
  }
  
  if (permission.check(actionsPermissions.debtReport) && !!businessSettings?.credit_sales) {
    availableReportTypes.push({name: "Customer Balance Summary", value: "credit"})
  }
  
  if (permission.check(actionsPermissions.wipReport) && !!businessSettings?.wip) {
    availableReportTypes.push({name: "Wip", value: "wip"})
  }
  
  if (permission.check(actionsPermissions.supplierBalanceSummaryReport)) {
    availableReportTypes.push({name: "Supplier Balance Summary", value: "supplier_balance_summary"})
  }
  
  if (permission.check(actionsPermissions.supplierBalanceDetailsReport)) {
    availableReportTypes.push({name: "Supplier Balance Details", value: "supplier_balance_details"})
  }
  
  if (permission.check(actionsPermissions.purchaseOrderReport)) {
    availableReportTypes.push({name: "Purchase Item Report", value: "purchase_by_item_report"})
  }
  
  if (permission.check(actionsPermissions.customerSubscription) && !!businessSettings?.subscription_services) {
    availableReportTypes.push({name: "Subscription", value: "subscription"})
  }
  
  return availableReportTypes
}


export const useProductValuationProductFilter = () => {
  const filters = []
  const {data: userProfile} = useUserProfile()
  
  if (onlyShowModulesBasedOnAccountsId(userProfile, PRODUCTION_MODULE_ACCOUNTS)) {
    filters.push(
      {name: "All", value: "all"},
      {name: "General Products", value: "general_product"},
      {name: "Raw Materials", value: "raw_material"},
      {name: "Producible", value: "producible"}
    )
  }
  
  return filters
}