import {scrollToTop} from "../../../utils";
import queryKeys from "../queryKeys";
import {
	clearTableService,
	createTableService,
	deleteTableService,
	editTableService,
	getTableService
} from "../../../services/tableServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useMutation, useQuery, useQueryClient} from "react-query";
import errorHandler from "../../../utils/errorHandler";


const useTableQuery = (searchValue) => {
	scrollToTop()
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery({
		queryKey: [queryKeys.TABLES, searchValue],
		queryFn: getTableService.bind(this, businessId, searchValue)
	})
}

const useCreateTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useMutation({
		mutationFn: data => {
			return createTableService({ ...data, business: businessId })
		},

		onSuccess: () => {
			successFn()
			toast.success("Table Created!!")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useEditTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: (data) => {
			return editTableService(data.id, data)
		},

		onSuccess: () => {
			successFn()
			toast.success("Edit Successful!!")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useDeleteTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: (tableId) => {
			return deleteTableService(tableId)
		},

		onSuccess: () => {
			successFn()
			toast.success("Delete Successful!!")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useClearTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: (tableId) => {
			return clearTableService(tableId)
		},

		onSuccess: () => {
			successFn()
			toast.success("Table Cleared")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export {
	useTableQuery, useCreateTableMutation, useEditTableMutation,
	useDeleteTableMutation, useClearTableMutation
}