import {Fragment, useState} from "react";
import {
  Chips,
  InvoiceCheckOutModal,
  Line,
  OrderDetailsCard,
  PageHeader,
  ScreenLoading,
  Table,
  TableItemWithStatus,
  UpdatePurchaseOrderModal
} from "../../../../components";
import {
  usePurchaseOrderByIdQuery,
  useUpdatePurchaseOrderMutation
} from "../../../../hooks/useServices/usePurchaseOrder";
import {
  CustomerDataStyled,
  OrdersTableStyled,
  SingleOrderHeaderStyled,
  SingleOrderLayoutStyled
} from "../../../../styles/singleOrderStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {ArrowLeft, EmptyWallet} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {getOrderStatus} from "../../../../utils/table";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../../data/permissions";
import {useTheme} from "styled-components";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useModal from "../../../../hooks/useModal";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {useSupplierByIdQuery} from "../../../../hooks/useServices/useSupplierServices";
import usePaymentMethods from "../../../../hooks/useUtils/usePaymentMethods";
import {purchaseOrderFormDefaultValues} from "../../../../data/defaultFormValues";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {useDownloadPurchaseOrderReceiptMutation} from "../../../../hooks/useServices/useReportServices";
import {handleFormattingOfPurchaseOrderForUpdating} from "../../../../utils/formatForms/purchaseOrderFormatter";
import {globalReducerActions} from "../../../../reducers/globalReducer";


const ViewPurchaseOrder = () => {
  const theme = useTheme()
  const { navigate, toast, getBusinessId, globalReducer } = useGlobalContext()
  const { purchaseOrderPaymentMethods } = usePaymentMethods()
  const businessSettings = useBusinessSettingQuery()
  
  const [editFormValues] = useState(purchaseOrderFormDefaultValues)
  const [payFormValues, setPayEditFormValues] = useState(purchaseOrderFormDefaultValues)
  
  const [showEditProductModal, setShowEditProductModal] = useModal(false)
  const [showInvoiceCheckoutModal, setShowInvoiceCheckoutModal] = useModal()
  const { purchaseOrderProductsTable, purchaseOrderPaymentRecordTable } = tableHeadersAndValueKeys
  
  const {currencyFormatter, dateFormatter, dateOnlyFormatter} = useDataTypeFormatter()
  
  const handleCloseModal = () => {
    if(showEditProductModal) {
      setShowEditProductModal()
    }
    
    if(showInvoiceCheckoutModal) {
      setShowInvoiceCheckoutModal()
    }
  }
  
  const { data: singlePurchaseOrder, status, refetch, isSuccess: isPurchaseOrderSuccess, isLoading: isPurchaserOrderLoading } = usePurchaseOrderByIdQuery()
  const updatePurchaseOrderMutation = useUpdatePurchaseOrderMutation({ successFn: handleCloseModal })
  const { data: supplier } = useSupplierByIdQuery(singlePurchaseOrder?.supplier)
  const downloadReceiptMutation = useDownloadPurchaseOrderReceiptMutation({ successFn: () => {} })
  
  const handleUpdatePurchaseOrder = (values) => {
    if (!values?.receive) {
      updatePurchaseOrderMutation.mutate({...values, edit: true})
      return
    }
  
    if (!values?.is_paid) {
      updatePurchaseOrderMutation.mutate({...values, supplied: true})
      return
    }
  
    setShowEditProductModal()
    setPayEditFormValues({...values, supplied: true})
    setShowInvoiceCheckoutModal()
  }
  
  const handleSetUpdatePurchaseOrder = (receive) => {
    const purchaseOrderValues = handleFormattingOfPurchaseOrderForUpdating({
      purchaseOrders: singlePurchaseOrder,
      businessSettings, currencyFormatter, receive
    })
  
    globalReducer({type: globalReducerActions.SET_RESTOCK_PRODUCTS, products: purchaseOrderValues})
    navigate(`/dashboard/inventory/purchase_order/update/${singlePurchaseOrder.id}`)
  }
  
  const handlePayForPurchaseOrder = (values) => {
    const formValues = {
      ...values,
      paid: true,
      supplied: false,
      id: singlePurchaseOrder.id,
      paid_amount: Number(getTotalAmount()).toFixed(2),
      business_id: getBusinessId(),
      ...(payFormValues?.supplied_data && payFormValues),
    }
    
    if(values.pay_mode === "partial") {
      if(Number(values.total_partial) > getTotalPayableAmount()) {
        toast.error("You can not pay more than your balance")
        return
      }
      
      formValues.payment_mode = "partial"
      formValues.paid_amount = Number(values.total_partial).toFixed(2)
  
      if(Number(singlePurchaseOrder.total_amount) !== getTotalPayableAmount()) {
        formValues.pay_balance = true
        delete formValues.payment_mode
        delete formValues.paid
        
        if(!!formValues?.supplied_data) {
          delete formValues.paid
          formValues.supplied = true
        }
  
      }
  
      updatePurchaseOrderMutation.mutate(formValues)
      return
    }
  
    formValues.payment_mode = formValues.pay_mode
    updatePurchaseOrderMutation.mutate(formValues)
  }
  
  const isPurchaseCancelledOrConfirmed = () => {
    return ["confirmed", "cancelled"].includes(singlePurchaseOrder?.status)
  }
  
  const isOrderPaidFully = () => {
    return singlePurchaseOrder?.payment_status === "paid"
  }
  
  const isAnyPaymentMade = () => {
    return Number(singlePurchaseOrder?.paid_amount) > 0 || singlePurchaseOrder?.status === "confirmed"
  }
  
  const isOrderStatusCancelled = () => {
    return singlePurchaseOrder?.status === "recall"
  }
  
  const handleCancelOrder = () => {
    toast.confirm(
      "Are you sure you want to cancel this order?",
      () => updatePurchaseOrderMutation.mutate({status: "recall", id: singlePurchaseOrder.id})
    )
  }
  
  const getTotalItemCost = () => {
    return singlePurchaseOrder?.products?.reduce((prev, curr) => {
      return prev + (Number(curr?.cost_price) * Number(curr?.supplied_quantity || curr?.requested_quantity)) || 0
    }, 0)
  }
  
  const getTotalAmount = () => {
    return getTotalItemCost() + Number(singlePurchaseOrder?.tax || 0) + Number(singlePurchaseOrder?.shipping_cost || 0)
  }
  
  const orderDetails = [
    {key: "Total Item Cost", value: currencyFormatter(getTotalItemCost() || 0)},
    {key: "Total Amount", value: currencyFormatter(getTotalAmount() || 0)},
    {key: "Date", value: dateFormatter(singlePurchaseOrder?.created_at || new Date())},
    ...(!!singlePurchaseOrder?.due_date ? [{
      key: "Payment Due Date", value: dateOnlyFormatter(singlePurchaseOrder?.due_date)
    }] : [])
  ]
  
  const supplierDetails = [
    { key: "Name", value: supplier?.name },
    { key: "Email", value: supplier?.email || "No Email" },
    { key: "Phone", value: supplier?.phone || "No Phone number" },
  ]
  
  const getAllPurchaseOrderProductsData = () => {
    return singlePurchaseOrder?.products?.map(product => {
      const requestQuantity = Number(product.requested_quantity) / Number(product.base_quantity || 1)
      let suppliedQuantity = Number(product?.supplied_quantity) / Number(product.base_quantity || 1)
  
      // if purchase has been received & no supplied quantity value
      if (singlePurchaseOrder.received_by && !product?.supplied_quantity) {
        suppliedQuantity = requestQuantity
      }
  
      return {
        ...product,
        cost_price: Number(product.cost_price) * Number(product.base_quantity || 1),
        requested_quantity: `${requestQuantity} ${product.unit_measurement || ""}`,
        supplied_quantity: !!suppliedQuantity ? `${suppliedQuantity} ${product.unit_measurement || ""}` : "N/A",
        total_cost: Number(product?.supplied_quantity || product?.requested_quantity) * Number(product.cost_price)
      }
    }) || []
  }
  
  const getTotalPayableAmount = () => {
    if(Number(singlePurchaseOrder?.paid_amount) === 0) {
      return getTotalAmount()
    }
    
    return singlePurchaseOrder?.balance
  }
  
  const getPaymentMethod = () => {
    if(Number(singlePurchaseOrder?.balance) === 0 && Number(singlePurchaseOrder?.paid_amount) === 0) {
      return purchaseOrderPaymentMethods
    }
    
    if(singlePurchaseOrder?.payment_mode === "credit") {
      return [
        { title: "Paid in full", value: "paid", Icon: EmptyWallet },
        { title: "Partial", value: "partial", Icon: EmptyWallet },
      ]
    }
  
    return [
      { title: "Partial", value: "partial", Icon: EmptyWallet },
    ]
  }
  
  
  const handleDownloadPurchaseOrder = () => {
    if(downloadReceiptMutation.isLoading) return
    downloadReceiptMutation.mutate(singlePurchaseOrder.purchase_order_id)
  }
  
  return (
    <div>
      {status === "error" && (
        <ScreenLoading refetchFn={refetch} />
      )}
  
      {showInvoiceCheckoutModal && <InvoiceCheckOutModal balance={getTotalPayableAmount()} onlyOnePartial orders={[singlePurchaseOrder]} onClose={setShowInvoiceCheckoutModal} customPaymentMethods={getPaymentMethod()} handlePaymentSubmission={handlePayForPurchaseOrder} />}
      {showEditProductModal && <UpdatePurchaseOrderModal formValues={editFormValues} onClose={setShowEditProductModal} mutation={updatePurchaseOrderMutation} handleSubmit={handleUpdatePurchaseOrder} />}
  
      <PageHeader title="Purchase Details" />
      
      <Fragment>
        <SingleOrderHeaderStyled>
          <FlexStyled className="navigation_section">
            <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
            <FlexStyled>
              <Paragraph noTop color="menuGray">Back to list of purchase</Paragraph>
        
              <FlexStyled className="id_and_status">
                {isPurchaseOrderSuccess && (
                  <Fragment>
                    <Heading color="black">Order: <span>{singlePurchaseOrder?.purchase_order_id}</span></Heading>
                    {getOrderStatus(singlePurchaseOrder?.status)}
                  </Fragment>
                )}
          
                {isPurchaserOrderLoading && (
                  <Fragment>
                    <TableSkeleton count={1} noTop height={30} width={140} />
                    <TableSkeleton count={1} noTop height={30} width={140} />
                  </Fragment>
                )}
              </FlexStyled>
            </FlexStyled>
          </FlexStyled>
    
          <FlexStyled className="chips">
            {isPurchaseOrderSuccess && (
              <Fragment>
                <Chips title="Download" active onClick={handleDownloadPurchaseOrder}
                       permissionCode={actionsPermissions.createPurchaseOrder}/>
  
                {!isAnyPaymentMade() && (
                  <Chips title="Update Purchase" active onClick={() => handleSetUpdatePurchaseOrder()}
                         permissionCode={actionsPermissions.updatePurchaseOrder}/>
                )}
  
                {!isPurchaseCancelledOrConfirmed() && (
                  <Chips title="Receive from P.O" active onClick={() => handleSetUpdatePurchaseOrder(true)}
                         permissionCode={actionsPermissions.receivePurchaseOrder}/>
                )}
  
                {(!isOrderPaidFully()) && (
                  <Chips title={!isAnyPaymentMade() ? "Pay Bill" : "Pay Balance"} active
                         onClick={setShowInvoiceCheckoutModal} permissionCode={actionsPermissions.payForPurchaseOrder}/>
                )}
    
                {(!isOrderStatusCancelled() && singlePurchaseOrder?.status === "confirmed") && (
                  <Chips title="Recall" permissionCode={actionsPermissions.recallPurchaseOrder} active
                         onClick={handleCancelOrder}/>
                )}
              </Fragment>
            )}
      
            {isPurchaserOrderLoading && (
              <Fragment>
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
              </Fragment>
            )}
          </FlexStyled>
        </SingleOrderHeaderStyled>
  
        <SingleOrderLayoutStyled>
          <div className="main">
            {isPurchaseOrderSuccess && (
              <FlexStyled className="order_staff_bank">
                <OrderDetailsCard half title="Order" data={orderDetails} />
                <OrderDetailsCard half title="Supplier" data={supplierDetails} />
                {/*<OrderDetailsCard title="Bank" data={bankDetails} />*/}
              </FlexStyled>
            )}
  
  
            {isPurchaseOrderSuccess && (
              singlePurchaseOrder?.payment_log?.length > 0 && (
                <OrdersTableStyled>
        
                  <SpaceBetweenStyled>
                    <Heading size={1.4}  color="black">Payment Records</Heading>
                    {Number(singlePurchaseOrder?.balance) > 0 && (
                      <FlexStyled>
                        <Heading size={1.2}  color="black">Balance:</Heading>
                        <Heading size={1.2}  color="error">{currencyFormatter(singlePurchaseOrder?.balance)}</Heading>
                        <span> </span>
                        <span></span>
                      </FlexStyled>
                    )}
                  </SpaceBetweenStyled>
        
                  <Table headers={purchaseOrderPaymentRecordTable.headers} noCols={purchaseOrderPaymentRecordTable.headers.length}>
                    {singlePurchaseOrder?.payment_log.map((item, k) => (
                      <Fragment key={k}>
                        <TableItemWithStatus
                          count={k}
                          pageCount={1}
                          data={item}
                          keys={purchaseOrderPaymentRecordTable.values}
                          noAction
                          statusPosition={30} />
                        <Line />
                      </Fragment>
                    ))}
                  </Table>
                </OrdersTableStyled>
              )
            )}
  
            {isPurchaserOrderLoading && (
              <TableSkeleton count={2} height={70} />
            )}
  
  
            {isPurchaseOrderSuccess && (
              <OrdersTableStyled>
                <Heading size={1.4}  color="black">Purchased Products</Heading>
                <Table headers={purchaseOrderProductsTable.headers} equalWidth noCols={purchaseOrderProductsTable.headers.length}>
                  {getAllPurchaseOrderProductsData()?.length > 0 && (
                    [...getAllPurchaseOrderProductsData()]?.map((items, k) =>
                      <TableItemWithStatus
                        count={k}
                        pageCount={1}
                        keys={purchaseOrderProductsTable.values}
                        statusPosition={9}
                        key={k}
                        data={items}
                        noAction
                      />
                    ))}
        
                  {getAllPurchaseOrderProductsData()?.length < 1 && (
                    <NoQueryData text="No Purchase Order" />
                  )}
                </Table>
              </OrdersTableStyled>
            )}
          </div>
          
          
          <div className="side">
            {/*{isPurchaseOrderSuccess && (*/}
            {/*  <CustomerDataStyled>*/}
            {/*    <Heading size={1.4} color="black">Supplier</Heading>*/}
            
            {/*    {!!supplier?.name && (*/}
            {/*      <Fragment>*/}
            {/*        <div className="details">*/}
            {/*          <FlexStyled $gap={.6}>*/}
            {/*            <Heading color="black">Full Name:</Heading>*/}
            {/*            <Paragraph color="black" noTop>*/}
            {/*              <Link to="#">{supplier.name}</Link>*/}
            {/*            </Paragraph>*/}
            {/*          </FlexStyled>*/}
            {/*          <Line />*/}
            {/*        </div>*/}
            
            {/*        <div className="details">*/}
            {/*          <FlexStyled $gap={.6}>*/}
            {/*            <Heading color="black">Email Address:</Heading>*/}
            {/*            <Paragraph color="black" noTop>{supplier?.email || "No Email"}</Paragraph>*/}
            {/*          </FlexStyled>*/}
            {/*          <Line />*/}
            {/*        </div>*/}
            
            {/*        <div className="details">*/}
            {/*          <FlexStyled $gap={.6}>*/}
            {/*            <Heading color="black">Phone Number:</Heading>*/}
            {/*            <Paragraph color="black" noTop>{supplier?.phone || "No Phone number"}</Paragraph>*/}
            {/*          </FlexStyled>*/}
            {/*          <Line />*/}
            {/*        </div>*/}
            
            {/*        <div className="details">*/}
            {/*          <FlexStyled $gap={.6}>*/}
            {/*            <Heading color="black">Address:</Heading>*/}
            {/*            <Paragraph color="black" noTop>*/}
            {/*              {supplier?.address || "No Address"}*/}
            {/*            </Paragraph>*/}
            {/*          </FlexStyled>*/}
            {/*          <Line />*/}
            {/*        </div>*/}
            
            {/*        <div className="details">*/}
            {/*          <FlexStyled $gap={.6}>*/}
            {/*            <Heading color="black">Contact Person:</Heading>*/}
            {/*            <Paragraph color="black" noTop>{supplier?.contact_person || "No contact person"}</Paragraph>*/}
            {/*          </FlexStyled>*/}
            {/*          <Line />*/}
            {/*        </div>*/}
            
            {/*        <div className="details">*/}
            {/*          <FlexStyled $gap={.6}>*/}
            {/*            <Heading color="black">Contact Phone:</Heading>*/}
            {/*            <Paragraph color="black" noTop>*/}
            {/*              {supplier?.contact_phone || "No contact phone"}*/}
            {/*            </Paragraph>*/}
            {/*          </FlexStyled>*/}
            {/*          <Line />*/}
            {/*        </div>*/}
            {/*      </Fragment>*/}
            {/*    )}*/}
            {/*  </CustomerDataStyled>*/}
            {/*)}*/}
            
            {/*{isPurchaserOrderLoading && (*/}
            {/*  <CustomerDataStyled>*/}
            {/*    <TableSkeleton count={5} height={35} />*/}
            {/*  </CustomerDataStyled>*/}
            {/*)}*/}
  
            {isPurchaseOrderSuccess && (
              <CustomerDataStyled>
                <Heading size={1.4}  color="black">Summary</Heading>
  
                <SpaceBetweenStyled>
                  <Heading color="secondary">Total Item cost:</Heading>
                  <Heading color="secondary">{currencyFormatter(getTotalItemCost() || 0)}</Heading>
                </SpaceBetweenStyled>
  
                <SpaceBetweenStyled>
                  <Heading color="secondary">Tax:</Heading>
                  <Heading color="secondary">+ {currencyFormatter(singlePurchaseOrder?.tax || 0)}</Heading>
                </SpaceBetweenStyled>
  
                <SpaceBetweenStyled>
                  <Heading color="secondary">Shipping Cost:</Heading>
                  <Heading color="secondary">+ {currencyFormatter(singlePurchaseOrder?.shipping_cost || 0)}</Heading>
                </SpaceBetweenStyled>
  
                <Line />
  
                <SpaceBetweenStyled className="total">
                  <Heading color="black" bold size={1.1}>Total:</Heading>
                  <Heading color="black" bold size={1.1}>{currencyFormatter(getTotalAmount() || 0)}</Heading>
                </SpaceBetweenStyled>
  
                <SpaceBetweenStyled className="id_and_status">
                  <Heading color="black">Payment Status:</Heading>
                  {getOrderStatus(singlePurchaseOrder?.payment_status)}
                </SpaceBetweenStyled>
  
                {singlePurchaseOrder?.remark && (
                  <SpaceBetweenStyled className="total">
                    <Heading color="black" bold size={1.1}>Remark:</Heading>
                    <Heading color="black" size={1.1}>{singlePurchaseOrder?.remark}</Heading>
                  </SpaceBetweenStyled>
                )}
                
              </CustomerDataStyled>
            )}
          </div>
        </SingleOrderLayoutStyled>
      </Fragment>
    </div>
  )
}


export default ViewPurchaseOrder