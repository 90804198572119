import {Fragment, memo, useEffect, useRef, useState} from "react";
import {ContainerStyled} from "../../styles/DashboardStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {ReportStyled} from "../../styles/reportStyles";
import {Button, DatePicker, Form, Line, PageHeader, Select, Table, TableItemWithEyeAction,} from "../../components";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import tableHeadersAndValueKeys from "../../data/tableHeadersAndValueKeys";
import {SearchNormal1} from "iconsax-react";
import {
  expensesSortBy,
  paymentReportSortBy,
  paymentStatusOptions,
  productTransferAndReceiveOptions,
  productTransferReportShowTypeOptions,
  productValuationSortBy,
  purchaseSortBy,
  reportTypeForSummary,
  salesSortBy,
  salesSummaryReport,
  statusOptions,
  subscriptionSortBy,
  supplierSortBy
} from "../../data/selectField/report";
import {useCustomerQuery} from "../../hooks/useServices/useCustomerServices";
import {useProductByBusinessIdQuery} from "../../hooks/useServices/useProductServices";
import {useStaffByBusinessIdQuery} from "../../hooks/useServices/useStaffServices";
import paymentMethodsList from "../../data/selectField/paymentMethodsList";
import {useFormikContext} from "formik";
import {
  useDeleteReportDownloadableMutation,
  useDownloadReportMutation,
  useReportDownloadableService,
  useReportMutation
} from "../../hooks/useServices/useReportServices";
import {useExpenseCategoryQuery} from "../../hooks/useServices/useExpenseServices";
import dateFilter, {dateFilterValue} from "../../data/selectField/dateFilter";
import handleDateFilterValue from "../../utils/handleDateFilterValue";
import {useProductValuationProductFilter, useReportTypeSelectField} from "../../hooks/useUtils/useDynamicSelectFields";
import useDisableBrowserScrollNavigation from "../../hooks/useUtils/useDisableBrowserScrollNavigation";
import {useSupplierByBusinessIdQuery} from "../../hooks/useServices/useSupplierServices";
import {useStoreQuery} from "../../hooks/useServices/useStoreServices";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {AiOutlineFileExcel} from "react-icons/ai";
import {useCategoriesQuery} from "../../hooks/useServices/useCategoryServices";
import NoQueryData from "../../components/Utils/NoQueryData";


const Report = () => {
  useDisableBrowserScrollNavigation()
  const {getBusinessId, toast} = useGlobalContext()
  const {reportOrderTable, reportDownloadableTable} = tableHeadersAndValueKeys
  
  const [{from_date, to_date}, setDate] = useState({
    from_date: "",
    to_date: ""
  })
  
  const [downloadValues, setDownloadValues] = useState({})
  const [filter, setFilter] = useState("")
  
  
  const [searchValues, setSearchValues] = useState({
    customerSearch: "", productSearch: "", staffSearch: "", supplierSearch: "", productCategory: ""
  })
  
  const {data: allStaffs} = useStaffByBusinessIdQuery(searchValues.staffSearch)
  const {data: allCustomers} = useCustomerQuery(searchValues.customerSearch)
  const {data: allProducts} = useProductByBusinessIdQuery(searchValues.productSearch)
  const {data: allSuppliers} = useSupplierByBusinessIdQuery(searchValues.supplierSearch)
  const {data: allProductCategories} = useCategoriesQuery(searchValues.productCategory)
  
  
  const additionalStateOptionsRef = useRef([])
  
  const [activeFilter, setActiveFilter] = useState("")
  
  useEffect(() => {
    if (activeFilter === "Customer") {
      additionalStateOptionsRef.current = allCustomers?.results || []
    }
    
    if (activeFilter === "Supplier") {
      additionalStateOptionsRef.current = allSuppliers?.results || []
    }
    
    if (activeFilter === "Product") {
      additionalStateOptionsRef.current = allProducts?.results || []
    }
    
    if (activeFilter === "Employee") {
      additionalStateOptionsRef.current = allStaffs?.results || []
    }
  
    if (activeFilter === "Payment Method") {
      additionalStateOptionsRef.current = paymentMethodsList
    }
  
    if (activeFilter === "Single Category") {
      additionalStateOptionsRef.current = allProductCategories?.results
    }
  
    // if (activeFilter === "Category") {
    //   additionalStateOptionsRef.current = allProductCategories?.results
    // }
  }, [allStaffs, allCustomers, allProducts, activeFilter, allSuppliers, allProductCategories])
  
  const {data: allExpenseCategory} = useExpenseCategoryQuery()
  
  const [tableType, _] = useState(reportOrderTable)
  const downloadReportMutation = useDownloadReportMutation()
  
  const reportMutation = useReportMutation()
  const {
    data: allReportDownloadable,
    isLoading: isReportDownloadableLoading,
    isSuccess: isReportDownloadableSuccess
  } = useReportDownloadableService()
  
  const deleteReportDownloadableMutation = useDeleteReportDownloadableMutation()
  
  const handleClearMutationAndSheet = () => {
    const spreadSheet = document.getElementsByClassName("x-spreadsheet")[0]
    if (spreadSheet) {
      reportMutation.reset()
      document.getElementById("report-excel-view").removeChild(spreadSheet)
    }
  }
  
  const handleSearchFilter = (value, key) => {
    handleClearMutationAndSheet()
  
    switch (key) {
      case "Customer":
        setSearchValues(prev => ({...prev, customerSearch: value}))
        break
      case "Supplier":
        setSearchValues(prev => ({...prev, supplierSearch: value}))
        break
      case "Product":
        setSearchValues(prev => ({...prev, productSearch: value}))
        break
      case "Employee":
        setSearchValues(prev => ({...prev, staffSearch: value}))
        break
      case "Single Category":
        setSearchValues(prev => ({...prev, productCategory: value}))
        break
      default:
    }
  }

  
  const sortByAfter = {
    customer: {
      label: "Customer",
      valueKey: "id",
      displayKey: "full_name",
      placeholder: "Select Customer",
      options: allCustomers?.results
    },
    supplier: {
      label: "Supplier",
      valueKey: "id",
      displayKey: "name",
      placeholder: "Select Supplier",
      options: allSuppliers?.results
    },
    product: {
      label: "Product",
      valueKey: "id",
      displayKey: "name",
      placeholder: "Select Product",
      options: allProducts?.results
    },
    staff: {
      label: "Employee",
      valueKey: "id",
      displayKey: "full_name",
      placeholder: "Select employees",
      options: allStaffs?.results
    },
    pay_mode: {
      label: "Payment Method",
      valueKey: "value",
      displayKey: "title",
      placeholder: "Select pay method",
      options: paymentMethodsList
    },
    category: {
      label: "Category",
      valueKey: "id",
      displayKey: "name",
      placeholder: "Select Expense category",
      options: allExpenseCategory?.results
    },
    product_category: {
      label: "Category",
      valueKey: "id",
      displayKey: "name",
      placeholder: "Select category",
      options: allProductCategories?.results
    },
    status: {
      label: "Status",
      valueKey: "value",
      displayKey: "name",
      placeholder: "Select status",
      options: statusOptions
    },
    payment_status: {
      label: "Payment Status",
      valueKey: "value",
      displayKey: "name",
      placeholder: "Select payment status",
      options: paymentStatusOptions
    }
  }

  const handleReport = (values) => {
    handleClearMutationAndSheet()
  
    const reportQuery = {
      download: true,
      sort_by: values.sortBy,
      business_id: getBusinessId(),
      param: values.additionalState,
      report_type: values.reportType,
      summary: values.summary || false,
      to_date: (filter === dateFilterValue.CUSTOM_PERIOD) ? values.toDate : to_date,
      from_date: (filter === dateFilterValue.CUSTOM_PERIOD) ? values.fromDate : from_date,
    }
  
    if (reportQuery.report_type === "get_product_transfer_receive_history") {
      reportQuery["param"] = values?.report_product
      reportQuery['status'] = values?.report_status
      reportQuery[`receive_biz`] = values?.report_business
      reportQuery["summary"] = Boolean(values.report_show_type)
      reportQuery['sort_by'] = (values.report_type_name === "Product Transfer Report") ? "transfer" : "receive"
    }
  
    if (reportQuery.report_type === "valuation" && values['product_valuation_filter']) {
      reportQuery["filter"] = values["product_valuation_filter"] || "all"
      delete reportQuery.to_date
      delete reportQuery.from_date
    }
  
    setDownloadValues(reportQuery)
    reportMutation.mutate(reportQuery)
  }
  
  const handleDownloadReport = () => {
    downloadReportMutation.mutate(downloadValues)
  }
  
  const handleDateFilterSelect = (filterType) => {
    handleClearMutationAndSheet()
  
    setFilter(filterType.value)
  
    if (filterType.value === dateFilterValue.CUSTOM_PERIOD) {
      return
    }
  
    const {toDate, fromDate} = handleDateFilterValue(filterType.value)
    setDate({from_date: fromDate, to_date: toDate})
  }
  
  const handleDeleteDownloadable = (data) => {
    toast.confirm(
      "Do you want to delete this report",
      () => deleteReportDownloadableMutation.mutate(data.id),
      "delete_report"
    )
  }
  
  return (
    <div>
      <PageHeader title="Report"/>
      
      <ReportStyled>
        <ContainerStyled bg="white" padding>
          <Form values={{
            reportType: "",
            sortBy: "",
            additionalState: "",
            fromDate: "",
            toDate: "",
            sort_type_name: "",
            report_type_name: "",
            is_additional: {}
          }} onSubmit={handleReport}>
            <SpaceBetweenStyled>
              <FlexStyled>
                <ReportTypeSelectField reportMutation={reportMutation}/>
                
                <SortSelectField reportMutation={reportMutation} sortByAfter={sortByAfter}
                                 setActiveFilter={setActiveFilter}/>
                <AdditionalSelectField handleSearchFilter={handleSearchFilter}
                                       additionalStateOptionsRef={additionalStateOptionsRef}/>
                
                <ProductTransferAndReceiveFields reportMutation={reportMutation}/>
                
                <ProductValuationFilterFields reportMutation={reportMutation}/>
                
                <DateFilterField
                  filter={filter}
                  handleDateFilterSelect={handleDateFilterSelect}
                  handleClearMutationAndSheet={handleClearMutationAndSheet}
                />
                
                <label htmlFor="submit_button">
                  <SearchNormal1 type="submit" size={22} color="white"/>
                </label>
                <Button id="submit_button"/>
                
                {(reportMutation.isSuccess && reportMutation.data.type === "excel") && (
                  <Button Icon={AiOutlineFileExcel} text="Download Report" disabled={reportMutation.isLoading}
                          onClick={handleDownloadReport} className="download_report"/>
                )}
              </FlexStyled>
            </SpaceBetweenStyled>
          </Form>
          
          <Line/> <br/>
          
          <div id="report-excel-view"/>
          
          <Table noPadding noHeader bg headers={tableType.headers} noCols={tableType.headers.length} equalWidth>
            {reportMutation.isLoading && (
              <TableSkeleton/>
            )}
          </Table>
        </ContainerStyled>
      </ReportStyled>
  
  
      <br/> <br/> <br/>
      <Fragment>
        <PageHeader title="Downloadable"/>
    
        {isReportDownloadableLoading && (
          <TableSkeleton/>
        )}
    
        {isReportDownloadableSuccess && (
          <Table equalWidth headers={reportDownloadableTable.headers} noCols={reportDownloadableTable.headers.length}>
            {allReportDownloadable.map((report, k) => (
              <TableItemWithEyeAction
                downloadAction
                returnFullData
                count={k}
                pageCount={1}
                data={report}
                key={report.id}
                handleDelete={handleDeleteDownloadable}
                onClick={(data) => window.open(data?.file_path)}
                keys={reportDownloadableTable.values}
              />
            ))}
        
            {allReportDownloadable.length === 0 && (
              <NoQueryData text="No downloadable report"/>
            )}
          </Table>
        )}
      </Fragment>
    </div>
  )
}

export default Report;

const DateFilterField = ({filter, handleClearMutationAndSheet, handleDateFilterSelect}) => {
  const {values} = useFormikContext()
  
  const reportTypeToDisableDateFilter = ["valuation"]
  return (
    <Fragment>
      {!reportTypeToDisableDateFilter.includes(values.reportType) && (
        <>
          <Select
            label="Date filter"
            name="filter"
            valueKey="value"
            displayKey="name"
            options={dateFilter}
            displayName="display_filter"
            placeholder="Filter with date"
            updateFn={handleDateFilterSelect}
            noBottomMargin
          />
          
          {(filter === dateFilterValue.CUSTOM_PERIOD) && (
            <Fragment>
              <DatePicker noBottomMargin onChange={handleClearMutationAndSheet} label="Start report from"
                          labelBg="white"
                          required name="fromDate" placeholder="Date from"/>
              <DatePicker noBottomMargin onChange={handleClearMutationAndSheet} label="End report at"
                          labelBg="white" required
                          name="toDate" placeholder="Date to"/>
            </Fragment>
          )}
        </>
      )}
    </Fragment>
  )
}

const AdditionalSelectField = memo(({ handleSearchFilter, additionalStateOptionsRef }) => {
  const { values: { is_additional: additionalState } } = useFormikContext()

  return (
    <Fragment>
      {!!additionalState?.label && (
        <Select
          label={additionalState?.label}
          options={additionalStateOptionsRef.current || []}
          displayKey={additionalState?.displayKey}
          valueKey={additionalState?.valueKey}
          name="additionalState"
          displayName="additional_state_name"
          placeholder={additionalState?.placeholder}
          updateQueryResult={handleSearchFilter}
          updateQueryResultName={additionalState?.label}
          noBottomMargin
        />
      )}
    </Fragment>
  )
})


const ReportTypeSelectField = memo(function ReportTypeSelectField({reportMutation}) {
  const {setFieldValue} = useFormikContext()
  const reportType = useReportTypeSelectField()
  
  const handleUpdate = (data) => {
    setFieldValue("sort_type_name", "")
    setFieldValue("sortBy", "")
    setFieldValue("is_additional", {})
  
    if (reportTypeForSummary.includes(data?.name)) {
      setFieldValue("summary", true)
    } else {
      setFieldValue("summary", false)
    }
  
    const spreadSheet = document.getElementsByClassName("x-spreadsheet")[0]
    if (spreadSheet) {
      reportMutation.reset()
      document.getElementById("report-excel-view").removeChild(spreadSheet)
    }
  }
  
  return (
    <Select
      label="Report Type"
      options={reportType}
      displayKey="name"
      valueKey="value"
      name="reportType"
      updateFn={handleUpdate}
      displayName="report_type_name"
      placeholder="Select report type"
      noBottomMargin
    />
  )
})


const SortSelectField = memo(({sortByAfter, setActiveFilter, reportMutation}) => {
  const {setFieldValue, values} = useFormikContext()
  
  const handleSortBy = (data) => {
    let sortByAfterKey = data.value
  
    if (["sales", "valuation"].includes(values.reportType) && data.value === "category") {
      sortByAfterKey = "product_category"
    }
  
    if (data.name === "All Category") {
      sortByAfterKey = "no_additional_option"
    }
  
    setFieldValue("additional_state", "")
    setFieldValue("additionalState", "")
    setFieldValue("additional_state_name", "")
    setFieldValue("is_additional", sortByAfter[sortByAfterKey])
  
    setActiveFilter(data.name)
  
    // setAdditionalState(sortByAfter[data.value])
    const spreadSheet = document.getElementsByClassName("x-spreadsheet")[0]
    if (spreadSheet) {
      reportMutation.reset()
      document.getElementById("report-excel-view").removeChild(spreadSheet)
    }
  }
  
  const getSortByOptions = () => {
    if (values.reportType === "sales" && values.report_type_name === "Sales Summary") return salesSummaryReport
  
    if (values.reportType === "sales") return salesSortBy
    if (values.reportType === "group_sales") return salesSortBy
    if (values.reportType === "expenses") return expensesSortBy
    if (values.reportType === "subscription") return subscriptionSortBy
    if (values.reportType === "supplier_balance_summary") return supplierSortBy
    if (values.reportType === "supplier_balance_details") return supplierSortBy
    if (values.reportType === "purchase_by_item_report") return purchaseSortBy
    if (values.reportType === "payment_report") return paymentReportSortBy
    if (values.reportType === "valuation") return productValuationSortBy
  }
  
  const sortValues = ["sales", "group_sales", "expenses", "subscription", "supplier_balance_summary", "supplier_balance_details", "purchase_by_item_report", "payment_report", "valuation"]
  
  return (
    <Fragment>
      {sortValues.includes(values.reportType) && (
        <Select
          label="Sort by"
          options={getSortByOptions()}
          displayKey="name"
          valueKey="value"
          name="sortBy"
          displayName="sort_type_name"
          placeholder="Select sort by"
          updateFn={handleSortBy}
          noBottomMargin
        />
      )}
    </Fragment>
  )
})


const ProductTransferAndReceiveFields = memo(function ({reportMutation}) {
  const {values} = useFormikContext()
  const {data: allStores} = useStoreQuery("")
  const {data: allProducts} = useProductByBusinessIdQuery("")
  
  const handleClearReport = () => {
    const spreadSheet = document.getElementsByClassName("x-spreadsheet")[0]
    if (spreadSheet) {
      reportMutation.reset()
      document.getElementById("report-excel-view").removeChild(spreadSheet)
    }
  }
  
  return (
    values.reportType === "get_product_transfer_receive_history" && (
      <Fragment>
        <Select
          clearFilter
          label="Report View"
          options={productTransferReportShowTypeOptions}
          displayKey="name"
          valueKey="value"
          name="report_show_type"
          displayName="report_show_type_name"
          placeholder="Select report view"
          noBottomMargin
          updateFn={handleClearReport}
        />
        
        <Select
          optional
          clearFilter
          label="Filter By Status"
          options={productTransferAndReceiveOptions}
          displayKey="name"
          valueKey="value"
          name="report_status"
          displayName="report_status_name"
          placeholder="Select status"
          noBottomMargin
          updateFn={handleClearReport}
        />
        
        <Select
          clearFilter
          label="Filter By Business"
          optional
          options={allStores}
          displayKey="store_name"
          valueKey="id"
          name="report_business"
          displayName="report_business_name"
          placeholder="Select business"
          noBottomMargin
          updateFn={handleClearReport}
        />
        
        <Select
          clearFilter
          label="Filter By Product"
          optional
          options={allProducts?.results || []}
          displayKey="name"
          valueKey="id"
          name="report_product"
          displayName="report_product_name"
          placeholder="Select Product"
          noBottomMargin
          updateFn={handleClearReport}
        />
      </Fragment>
    )
  )
})


const ProductValuationFilterFields = memo(function ({reportMutation}) {
  const {values} = useFormikContext()
  const productValuationProductFilter = useProductValuationProductFilter()
  
  const handleClearReport = () => {
    const spreadSheet = document.getElementsByClassName("x-spreadsheet")[0]
    if (spreadSheet) {
      reportMutation.reset()
      document.getElementById("report-excel-view").removeChild(spreadSheet)
    }
  }
  
  return (
    <Fragment>
      {(values.reportType === "valuation" && productValuationProductFilter.length > 0) && (
        <Fragment>
          <Select
            clearFilter
            label="Filter products by"
            options={productValuationProductFilter}
            displayKey="name"
            valueKey="value"
            name="product_valuation_filter"
            displayName="product_valuation_filter_name"
            placeholder="Filter products by type"
            noBottomMargin
            updateFn={handleClearReport}
          />
        </Fragment>
      )}
    </Fragment>
  )
})