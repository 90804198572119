import {Fragment, useEffect, useState} from "react";
import {ArrowDown2, ArrowUp2, Trash} from "iconsax-react";
import {useTheme} from "styled-components";
import {Button, Chips, Form, Image, Input, Line} from "../index";
import {Heading, Paragraph} from "../../styles/textStyles";
import {CartProductCardStyled, ProductCardCountStyled} from "../../styles/cardStyles";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {NoProductImage} from "../../assets/images";
import useSellContext from "../../hooks/useContexts/useSellContext";
import {sellReducerActions} from "../../reducers/sellReducer";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import {useCartProductTaxAndDiscountCalculation} from "../../hooks/useUtils/useCartCalculations";
import {useModal} from "../../hooks";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import {disableSellBelowStockWhenOfflineForAccount} from "../../utils/manageDisplayOfFeatures";


const CartProductCard = ({
                           product,
                           handleRemoveProductFromCart,
                           noAction,
                           handleAddToCartWithModal,
                           returned,
                           isReturnOrder
                         }) => {
  const theme = useTheme()
  const {data: userProfile} = useUserProfile()
  const [viewMoreDetails, setViewMoreDetails] = useModal()
  const {toast, isOffline} = useGlobalContext()
  const {cart, reducer} = useSellContext()
  const {currencyFormatter} = useDataTypeFormatter()
  const businessSettings = useBusinessSettingQuery()
  
  const {image_path, name, selling_price, service, unit, unit_increment, stock_unit} = product
  const [selectedAddOns, setSelectedAddOns] = useState(service || [])
  const [productCount, setProductCount] = useState(Number(unit || unit_increment || 1))
  
  const disableSellBelowStockWhenOffline = (isOffline && disableSellBelowStockWhenOfflineForAccount(userProfile?.[0]?.account))
  
  useEffect(() => {
    setProductCount(Number(unit || unit_increment || 1))
  }, [product])
  
  
  const ProductUnitModify = () => {
    const handleIncrement = (result) => {
      if (Number(result) > Number(stock_unit) && (!isOffline || disableSellBelowStockWhenOffline)) {
        if (!businessSettings?.sell_below_stock && product.use_stock) {
          toast.error("You have exceeded the stock available", "prevent_stock_increment")
          return
        }
      }
  
      setProductCount(Number(result))
  
      reducer({
        product: {
          ...product,
          unit: Number(result),
          updatedFromCartCard: true,
          isWithoutModal: false,
          ...(product?.selected_unit_measurement?.name && {
            selected_unit_measurement: {
              ...product?.selected_unit_measurement,
              quantity: Number(result)
            }
          })
        },
        type: sellReducerActions.ADD_PRODUCT_TO_CART
      })
    }

    const handleDynamicCountChange = (value) => {
      if (Number(value) > Number(stock_unit) && (!isOffline || disableSellBelowStockWhenOffline)) {
        if (!businessSettings?.sell_below_stock && product.use_stock) {
          toast.error("You have exceeded the stock available", "prevent_stock_increment")
          return
        }
      }
  
      setProductCount(Number(value))
  
      reducer({
        product: {
          ...product,
          unit: Number(value),
          updatedFromCartCard: true,
          isWithoutModal: false,
          ...(product?.selected_unit_measurement?.name && {
            selected_unit_measurement: {
              ...product?.selected_unit_measurement,
              quantity: Number(value)
            }
          })
        },
        type: sellReducerActions.ADD_PRODUCT_TO_CART
      })
    }
    
    const disableProductIncrement = () => {
      if (isOffline && !disableSellBelowStockWhenOffline) return false
      if (!product.use_stock) return false
      if (stock_unit >= productCount) return false
  
      return !businessSettings?.sell_below_stock;
    }
    
    return (
      <ProductCardCountStyled>
        <Button onClick={handleIncrement.bind(this, productCount - Number(unit_increment || 1))} text="-"
                disabled={productCount === Number(unit_increment || 1)}/>
        <Form enableReinitialize values={{count: productCount, count_format: productCount}}>
          <Input disabled={(productCount === stock_unit) && !isOffline} type="number" name="count"
                 onChange={handleDynamicCountChange}/>
        </Form>
        <Button onClick={handleIncrement.bind(this, productCount + Number(unit_increment || 1))} text="+"
                disabled={disableProductIncrement()}/>
      </ProductCardCountStyled>
    )
  }
  
  useEffect(() => {
    setSelectedAddOns(service)
  }, [service])
  
  const calculateTotalCost = (addOns = []) => {
    const totalPriceOfAddOns = addOns.reduce((previousValue, currentValue) => {
      return previousValue + currentValue?.cost
    }, 0)
    
    return Number((unit * Number(selling_price)) + Number(totalPriceOfAddOns)).toFixed(2)
  }

  const productTaxAndDiscountCalculation = useCartProductTaxAndDiscountCalculation({
    ...product, frontend_total_selling_price: calculateTotalCost(selectedAddOns)
  })
  
  const handleRemoveAddOn = (addOn) => {
    if (isReturnOrder) return
    
    toast.confirm(
      `Are you sure you want to delete addon: ${addOn.name}`,
      () => {
        const newServicesList = selectedAddOns.filter(item => item.id !== addOn.id)
        setSelectedAddOns(newServicesList)
        
        const newCart = cart.filter(productInCart => productInCart.id !== product.id)

        reducer({
          type: sellReducerActions.REFRESH_CART,
          cart: [...newCart, { ...product, service: newServicesList }]
        })
      },
      "remove_addon"
    )
  }

  return (
    <CartProductCardStyled onClick={() => handleAddToCartWithModal(product)} $returned={returned}>
      <SpaceBetweenStyled className="image_and_details">
        <FlexStyled>
          <Image height={75} src={image_path || NoProductImage} alt={name} />
          <div className="title_and_price">
            <Heading color="black" >{name}</Heading>
            <Heading color="secondary" thin size={.85}>
              {currencyFormatter(selling_price)}
              <b>(x{unit}{product?.selected_unit_measurement && ` ${product?.selected_unit_measurement?.name}`})</b>
              {product?.isComplimentary && <Paragraph noTop color="error"> Complimentary</Paragraph>}
              {!!returned && <Paragraph noTop color="error">Returned</Paragraph>}
            </Heading>
            
            <ProductUnitModify />
            
          </div>
        </FlexStyled>
      </SpaceBetweenStyled>
  
      {selectedAddOns?.length > 0 && (
        <Fragment>
          <Heading color="black">
            Add-On(s):
          </Heading>
  
          <FlexStyled className="add_ons" onClick={e => e.stopPropagation()}>
            {selectedAddOns?.map(item => (
              <Chips
                noAddonAction={isReturnOrder}
                key={item.id}
                isAddon={true}
                title={item.fullName}
                onClick={() => handleRemoveAddOn(item)}
              />
            ))}
          </FlexStyled>
        </Fragment>
      )}
  
      <SpaceBetweenStyled className="subtotal_and_remove_from_cart" onClick={e => e.stopPropagation()}>
        <FlexColumnStyled $gap={.2}>
          <FlexStyled className="subtotal">
            {viewMoreDetails ? (
              <ArrowUp2 onClick={setViewMoreDetails} size="22" variant="Bold" color="#000000"/>
            ) : (
              <ArrowDown2 onClick={setViewMoreDetails} size="22" variant="Bold" color="#000000"/>
            )}
    
            <Heading size={.9} className="pointer" bold onClick={setViewMoreDetails} color="black">
              Total Cost:
            </Heading>
            <Heading className="pointer" bold onClick={setViewMoreDetails} color="secondary" thin size={.9}>
              {currencyFormatter(productTaxAndDiscountCalculation.productTotalValue)}
            </Heading>
          </FlexStyled>
  
          {viewMoreDetails && (
            <FlexColumnStyled $gap={.3} style={{marginLeft: "2rem"}}>
              {!!productTaxAndDiscountCalculation.productOriginalCost && (
                <FlexStyled className="subtotal">
                  <Heading color="black">Product Cost: </Heading>
                  <Heading color="secondary" thin size={.9}>
                    {currencyFormatter(productTaxAndDiscountCalculation.productOriginalCost)}
                  </Heading>
                </FlexStyled>
              )}
  
              {!!productTaxAndDiscountCalculation.serviceAmount && (
                <FlexStyled className="subtotal">
                  <Heading color="black">Service(s): </Heading>
                  <Heading color="secondary" thin size={.9}>
                    +{currencyFormatter(productTaxAndDiscountCalculation.serviceAmount)}
                  </Heading>
                </FlexStyled>
              )}
  
              {!!productTaxAndDiscountCalculation.discountAmount && (
                <FlexStyled className="subtotal">
                  <Heading color="black">
                    Discount {productTaxAndDiscountCalculation.discountPercentage ? `(${productTaxAndDiscountCalculation.discountPercentage}%)` : ""}:
                  </Heading>
                  <Heading color="secondary" thin size={.9}>
                    -{currencyFormatter(productTaxAndDiscountCalculation.discountAmount)}
                  </Heading>
                </FlexStyled>
              )}
      
              {productTaxAndDiscountCalculation.subtractiveTaxArray.map(tax => (
                <FlexStyled className="subtotal" key={`${tax.title}_${tax.value}_${tax.tax_type}`}>
                  <Heading color="black">{tax.title} ({tax.value}% Tax): </Heading>
                  <Heading color="secondary" thin size={.9}>
                    ..{currencyFormatter(tax.taxAmount)}
                  </Heading>
                </FlexStyled>
              ))}
      
              {productTaxAndDiscountCalculation.additionalTaxArray.map(tax => (
                <FlexStyled className="subtotal" key={`${tax.title}_${tax.value}_${tax.tax_type}`}>
                  <Heading color="black">{tax.title} ({tax.value}% Tax): </Heading>
                  <Heading color="secondary" thin size={.9}>
                    {currencyFormatter(tax.taxAmount)}
                  </Heading>
                </FlexStyled>
              ))}
      
              <FlexStyled className="subtotal">
                <Heading color="black" bold>Total: </Heading>
                <Heading color="secondary" size={.9} bold>
                  {currencyFormatter(productTaxAndDiscountCalculation.productTotalValue)}
                </Heading>
              </FlexStyled>
            </FlexColumnStyled>
          )}
        </FlexColumnStyled>
    
        {(!noAction && !returned) && (
          <Trash size={18} color={theme.error} onClick={handleRemoveProductFromCart.bind(this, product.unique_id)}/>
        )}
      </SpaceBetweenStyled>

      <Line />
    </CartProductCardStyled>
  )
}

export default CartProductCard