const timeFrame = [
  { name: "Year", value: "year" },
  { name: "Month", value: "month" },
  { name: "Week", value: "week" },
  {name: "Day", value: "day"}
]

const salesSortBy = [
  {name: "General", value: "general"},
  {name: "Customer", value: "customer"},
  {name: "Product", value: "product"},
  {name: "Employee", value: "staff"},
  {name: "Payment Method", value: "pay_mode"},
]

export const salesSummaryReport = [
  {name: "General", value: "general"},
  {name: "Customer", value: "customer"},
  {name: "Product", value: "product"},
  {name: "Employee", value: "staff"},
  {name: "All Employees", value: "all_staff"},
  {name: "All Category", value: "category"},
  {name: "Single Category", value: "category"},
  {name: "Payment Method", value: "pay_mode"},
]

const supplierSortBy = [
  {name: "General", value: "general"},
  {name: "Supplier", value: "supplier"},
]

const purchaseSortBy = [
  {name: "General", value: "general"},
  {name: "Product", value: "product"},
  {name: "Supplier", value: "supplier"},
]

const expensesSortBy = [
  {name: "General", value: "general"},
  {name: "Category", value: "category"}
]

const subscriptionSortBy = [
  // {name: "Status", value: "status"},
  {name: "Payment Method", value: "pay_mode"},
  // {name: "Payment Status", value: "payment_status"}
]

const statusOptions = [
  {name: "Active", value: "active"},
  {name: "Inactive", value: "inactive"}
]

const paymentStatusOptions = [
  {name: "Pending", value: "pending"},
  {name: "Confirmed", value: "payment_confirmed"},
]

export const productTransferAndReceiveOptions = [
  {name: "Confirmed", value: "confirmed"},
  {name: "Pending", value: "pending"},
  {name: "Rejected", value: "rejected"},
]

export const productTransferReportShowTypeOptions = [
  {name: "Detailed Report", value: false},
  {name: "Summarised Report", value: true},
]

export const paymentReportSortBy = [
  {name: "General", value: "general"},
  {name: "Employee", value: "staff"},
]

export const productValuationSortBy = [
  {name: "General", value: "general"},
  {name: "All Category", value: "all_category"},
  {name: "Single Category", value: "category"},
]

export const reportTypeForSummary = ["Sales Summary", "Product Valuation Summary"]

export {
  timeFrame,
  salesSortBy,
  expensesSortBy,
  subscriptionSortBy,
  statusOptions,
  paymentStatusOptions,
  supplierSortBy,
  purchaseSortBy
}