import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";

const keyName = "products"
const productLists = [keyName, "lists"]
const	mainProductsList = [...productLists, "main"]


const productQueryKey = {
	all: [keyName],
	onlyLists: [...productLists],
	mainProductsList: mainProductsList,
	byQueryParams: (params) => [...mainProductsList, getAllObjectKeysWithValues(params)],
	byId: (productId) => [keyName, productId],
	faultyProducts: (params = {}) => [keyName, "faulty products", getAllObjectKeysWithValues(params)],
	byBusinessId: (searchValue) => [...mainProductsList, handleRedundantParam(searchValue)],
	sellable: (searchValue) => [...mainProductsList, "sellable", handleRedundantParam(searchValue)],
	sellableForCategoryBased: (queryParams) => [...mainProductsList, "sellable", queryParams],
	byCategoryId: (categoryId, searchValue) => [...productLists, categoryId, handleRedundantParam(searchValue)],
	sellableByCategoryId: (categoryId, searchValue) => [...productLists, "sellable", categoryId, handleRedundantParam(searchValue)]

}

const handleRedundantParam = (param) => {
	return param || undefined
}

export default productQueryKey