import {Fragment} from "react";
import {ArrowRight2} from "iconsax-react";
import {Heading} from "../../styles/textStyles";
import {MenuListStyled} from "../../styles/listStyles";
import {Image, ListDropDown} from "../index";
import {useLocation} from "react-router-dom";
import {isLinkActive, isLinkParentActive} from "../../utils";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {isPackageExpired} from "../../utils/expiryCheck";
import {
  CUSTOMER_SUBSCRIPTION_MODULE_ACCOUNTS,
  onlyShowModulesBasedOnAccountsId,
  PRODUCTION_MODULE_ACCOUNTS
} from "../../utils/manageDisplayOfFeatures";
import {MdOutlineFiberNew} from "react-icons/md";
import {routesPermissions} from "../../data/permissions";
import {reportPermissions} from "../../data/permissions/actionsPermissions";

const restrictedTitles = ["Employees", "Staff Roles", "Banks", "All Stores", "Delivery Rate", "Wip", "Table", "Tables"]
const allowWhenOffline = ["My Dashboard", "Dashboard", "Make a sale", "Sales History", "Sales With Due Date", "Products", "Categories", "Tax", "Add-On(s)", "Banks", "Discount", "Purchase"]

const MenuList = ({ item, title, Icon, dropDown, options, noBorder, link, onClick, ignorePermission, isNew }) => {
  const location = useLocation()
  const {toast, navigate, isOffline} = useGlobalContext()
  const isOptions = (!!options && options.length > 1)
  const permission = item?.permission
  
  const {data: userProfile} = useUserProfile()
  const {user_permission = [], user_type} = userProfile?.[0] || {}
  if (!user_permission.includes(permission) && user_type !== "client_admin" && user_type !== "staff" && !ignorePermission && permission !== routesPermissions.openRoute) return
  
  if (title === "Subscription" && !onlyShowModulesBasedOnAccountsId(userProfile, CUSTOMER_SUBSCRIPTION_MODULE_ACCOUNTS)) return
  if (title === "Production" && !onlyShowModulesBasedOnAccountsId(userProfile, PRODUCTION_MODULE_ACCOUNTS)) return
  if (title === "Report" && user_type !== "client_admin" && !Object.values(reportPermissions).some(reportPermissionCode => user_permission.includes(reportPermissionCode))) return
  
  const handleLink = (link) => {
    if (isPackageExpired(userProfile?.[0]?.package_details)) {
      if (user_type !== "client_admin") {
        toast.error("Please contact the store owner to renew package", "renew-package")
        return
      }
      
      toast.error("Please renew your package to continue", "renew-package")
      return
    }
    
    if (restrictedTitles.includes(title) && userProfile?.[0]?.package_details?.package_name === "basic") {
      toast.error("Please upgrade your package to access this feature", link)
      return
    }
    
    if (isOffline && !allowWhenOffline.includes(title)) {
      toast.error(`You can not access '${title}' when offline`, "offline")
      return
    }
    
    navigate(link)
  }
  
  return (
    <Fragment>
      {link && (
        <MenuListStyled onClick={() => handleLink(link)} active={isLinkActive(link, location)} noBorder={noBorder}>
          {(typeof Icon === "object" || typeof Icon === "function") ?
            <Icon size={20} color="black" /> :
            <Image src={Icon} />
          }
          <Heading size={1.1} color="black">{title}</Heading>
          {!!dropDown && <ArrowRight2 size={18} />}

          {isOptions && <ListDropDown options={options} />}
  
          {isNew && <MdOutlineFiberNew className="new_flag" color="red" size={33} />}
        </MenuListStyled>
      )}

      
      {!link && (
        <MenuListStyled active={isLinkParentActive(title, location)} noBorder={noBorder} onClick={onClick && onClick}>
          { typeof Icon === "object" ? <Icon size={20} color="black" /> : <Image src={Icon} /> }
          <Heading size={1.1} color="black">{title}</Heading>
          {!!dropDown && <ArrowRight2 size={18} />}

          {isOptions && <ListDropDown options={options} />}
  
          {isNew && <MdOutlineFiberNew className="new_flag" color="red" size={33} />}
        </MenuListStyled>
      )}
    </Fragment>
  )
}

export default MenuList