const isDataAnObject = (data) => {
	return typeof data === 'object' && data !== null && !Array.isArray(data)
}

const isObjectValueAnArray = (obj) => {
	return Array.isArray(Object.values(obj)[0])
}

const isObjectValueAString = (obj) => {
	return typeof Object.values(obj)[0] === "string"
}

const errorHandler = (data) => {
	try {
		if (isDataAnObject(data) && isObjectValueAString(data)) {
			return `(${Object.keys(data)[0]}) - ${Object.values(data)[0]}`.trim()
		}
		
		if (isDataAnObject(data) && isObjectValueAnArray(data)) {
			return `(${Object.keys(data)[0][0]}) ${Object.values(data)[0][0]}`.trim()
		}
		
		if (Array.isArray(data) && isDataAnObject(data[0])) {
			return `(${Object.keys(data[0])[0]}) ${Object.values(data[0])[0]}`.trim()
		}
		
		if (Array.isArray(data) && typeof data[0] === "string") {
			return `${data[0]}`.trim()
		}
		
		return `${data}`.trim()
	} catch (_) {
		return `${data}`.trim()
	}
}

export const isErrorPermissionBased = (data) => {
	const error = errorHandler(data)
	return error === "You do not have permission to perform this action."
}

export default errorHandler