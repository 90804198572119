import {Heading, Paragraph} from "../../../styles/textStyles";
import {DetailsStyled} from "./settingsStyles";
import {AllCenterStyled, FlexColumnStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {CheckBox, Form, Image} from "../../../components";
import {NormalReceiptImage, WayBillReceiptImage} from "../../../assets/images";

const RECEIPT_IMAGE_WIDTH = 70

const ReceiptSettings = () => {
  
  const handleSelectWayBill = (check) => {
    localStorage.setItem("isWayBill", check.toString())
  }
  
  return (
    <DetailsStyled>
      <SpaceBetweenStyled className="pageHeader">
        <div>
          <Heading size={1.3} color="black">Receipt Layout & Settings</Heading>
          <Paragraph color="secondary">Choose the type of receipt design you want for your business</Paragraph>
        </div>
      </SpaceBetweenStyled>
      
      <Form values={{normal_receipt: true, wall_bill_receipt: false}} class>
        <AllCenterStyled className="receipt_settings">
          <FlexColumnStyled>
            <Image width={RECEIPT_IMAGE_WIDTH} src={NormalReceiptImage} alt="Normal receipt"/>
            <CheckBox alwaysCheck checked name="normal_receipt" labelProp="Normal Receipt"/>
          </FlexColumnStyled>
          
          <FlexColumnStyled>
            <Image width={RECEIPT_IMAGE_WIDTH} src={WayBillReceiptImage} alt="Way bill receipt"/>
            <CheckBox checked={localStorage.getItem("isWayBill") === "true"} afterCheck={handleSelectWayBill}
                      name="wall_bill_receipt" labelProp="Way bill Receipt"/>
          </FlexColumnStyled>
        </AllCenterStyled>
      </Form>
    </DetailsStyled>
  )
}


export default ReceiptSettings