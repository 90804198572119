const findProductInArray = (products = [], productId) => {
  return products.find(product => product.id === productId)
}

export const findProductInCartToHandleMultipleUnitMeasurement = (cart = [], product = {}) => {
  if(!product?.selected_unit_measurement) {
    return cart.find(prod => (prod.id === product.id && !prod?.selected_unit_measurement))
  }
  
  return cart.find(prod => ((prod.id === product.id) && (prod?.selected_unit_measurement?.name === product?.selected_unit_measurement?.name)))
}

const filterProductOutById = (products = [], productId) => {
  return products.filter(product => ((product.id !== productId) && (product?.unique_id !== productId)) )
}

const getSumOfAllProductsInCart = (cart = []) => {
  return cart.reduce((prevValue, currValue) => {
    return prevValue + (Number(currValue.selling_price) * Number(currValue.unit))
  }, 0)
}

const getPercentageFromAmount = (taxPercentage, sumOfProducts) => {
  return ((sumOfProducts * taxPercentage) / 100)
}

const getTotalCostOfAllGroupOrder = (groupOrders = []) => {
  return groupOrders.reduce((previousValue, currentValue) => {
    return previousValue + Number(currentValue.total_amount)
  }, 0)
}

const getTotalItemCostOfAllGroupOrder = (groupOrders = []) => {
  return groupOrders.reduce((previousValue, currentValue) => {
    return previousValue + Number(currentValue.total_items_cost)
  }, 0)
}

const getBalanceOfAllGroupOrder = (groupOrders = []) => {
  return groupOrders.reduce((previousValue, currentValue) => {
    return previousValue + Number(currentValue.balance)
  }, 0)
}


export const addModifyOrderToLocalStorage = (products = [], groupIdUUID) => {
  localStorage.setItem("modify_order", JSON.stringify(products))
  localStorage.setItem("modify_order_group_id", groupIdUUID)
}

export const getModifyOrderGroupUUID = () => {
  return localStorage.getItem("modify_order_group_id")
}

/**
 * @return {(Array|null)}
 * */
export const getModifyOrderToLocalStorage = () => {
  const orders = localStorage.getItem("modify_order")
  return !!orders ? JSON.parse(orders) : null
}

export const clearModifyOrderFromLocalStorage = () => {
  localStorage.setItem("modify_order", null)
  localStorage.setItem("modify_order_group_id", null)
}

export const saveItemToCartOnExit = (products = []) => {
  localStorage.setItem("save_cart", JSON.stringify(products))
}

export const getSavedItemFromCart = () => {
  const orders = localStorage.getItem("save_cart")
  return !!orders && JSON.parse(orders)
}

export const clearSavedItemFromCart= () => {
  localStorage.setItem("save_cart", null)
}

export {
  findProductInArray, filterProductOutById, getSumOfAllProductsInCart, getBalanceOfAllGroupOrder,
  getPercentageFromAmount, getTotalCostOfAllGroupOrder, getTotalItemCostOfAllGroupOrder
}