import {useModal} from "../../hooks";
import {Heading, Paragraph} from "../../styles/textStyles";
import {EmptyBoxImage} from "../../assets/images";
import {sellReducerActions} from "../../reducers/sellReducer";
import useSellContext from "../../hooks/useContexts/useSellContext";
import {AddToCartModal, Button, CartProductCard, CheckOutModal, CustomerModal, Form, Image, Select} from "../index";
import {FlexColumnStyled, SectionStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {CartStyled, CartSummaryStyled, EmptyCartStyled} from "../../styles/sellStyles";
import useCartCalculations from "../../hooks/useUtils/useCartCalculations";
import {isAppStoreFront, isMobile} from "../../utils";
import {Link, useSearchParams} from "react-router-dom";
import {currencyFormatterFn} from "../../utils/dataTypeFormatter";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import useHoldOrders from "../../hooks/useUtils/useHoldOrders";
import HoldOrderCard from "../Card/HoldOrderCard";
import {Fragment, useEffect, useRef, useState} from "react";
import {
  clearModifyOrderFromLocalStorage,
  clearSavedItemFromCart,
  getModifyOrderToLocalStorage,
  getSavedItemFromCart,
  saveItemToCartOnExit
} from "../../utils/sell";
import env from "../../utils/env";
import {CloseSquare} from "iconsax-react";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {useCreateCustomerMutation, useCustomerQuery} from "../../hooks/useServices/useCustomerServices";
import {useCreateOrderMutation} from "../../hooks/useServices/useOrderServices";


const Cart = ({ onClose, onShowLogin }) => {
  const [searchParams] = useSearchParams()
  const [_, setTrigger] = useState("")
  const {toast, isOffline, navigate} = useGlobalContext()
  const [checkOutModal, setCheckOutModal] = useModal()
  const [showHoldOrders, setShowHoldOrders] = useModal()
  const {cart, reducer, holdOrderId, selectedCustomer} = useSellContext()
  const businessSettings = useBusinessSettingQuery()
  const [addToCartModal, setAddToCartModal] = useModal()
  const {holdOrder, getOrdersOnHold, selectHolderOnHold} = useHoldOrders()
  const {data: userProfile} = useUserProfile()
  
  const {currencyFormatter} = useDataTypeFormatter()
  
  const cartRef = useRef([])
  
  
  const {
    taxPercentage,
    discountPercentage,
    checkoutDiscount,
    checkoutDiscountPercentage,
    totalTax,
    totalDiscount,
    subTotal,
    overallCalculation,
    overallCalculationValue,
    totalServicesValue
  } = useCartCalculations(cart)
  
  useEffect(() => {
    cartRef.current = cart
  }, [cart])
  
  
  // IF PERSON LEAVES THE SELL PAGE AND THERE ARE ITEMS IN THE CART,
  // SAVE IT AND POPULATE IN THE CART WHEN THEY GET BACK
  useEffect(() => {
    if (isAppStoreFront()) return
  
    if (getSavedItemFromCart() && !getModifyOrderToLocalStorage()) {
      getSavedItemFromCart().forEach(order => {
        reducer({
          product: order,
          type: sellReducerActions.ADD_PRODUCT_TO_CART,
        })
      })
    }
  
    return () => {
      if (isAppStoreFront()) return
  
      if (cartRef.current.length === 0 && !getModifyOrderToLocalStorage()) {
        clearSavedItemFromCart()
      }
  
      // if there is item in the cart and no modify order, save the cart into storage
      // also if there is "modify order" and the person leaves the POS page, it won't save the cart into storage
      if (cartRef.current.length > 0 && !getModifyOrderToLocalStorage()) {
        saveItemToCartOnExit(cartRef.current)
      }
    }
  }, [])
  
  
  // IF PERSON IS MODIFYING AN ORDER,
  // GET ORDER FROM LOCALSTORAGE AND POPULATE IN THE CART
  useEffect(() => {
    if(isAppStoreFront()) return
  
    if(getModifyOrderToLocalStorage()) {
      getModifyOrderToLocalStorage().forEach(order => {
        reducer({
          product: order,
          type: sellReducerActions.ADD_PRODUCT_TO_CART,
        })
      })
    }
    
    return () => {
      if (isAppStoreFront()) return
      clearModifyOrderFromLocalStorage()
    }
  }, [])
  

  const handleRemoveProductFromCart = (productId) => {
    reducer({
      productId,
      type: sellReducerActions.REMOVE_PRODUCT_FROM_CART,
    })
  }
  
  const handleAddToCartWithModal = (product) => {
    if(!businessSettings.sell_below_stock && (Number(product.stock_unit) < 1) && product.use_stock) {
      toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
      return
    }
    
    reducer({
      product,
      type: sellReducerActions.SET_PRODUCT_FOR_ADD_TO_CART_MODAL
    })
    
    setAddToCartModal()
  }
  
  const handleHoldOrder = () => {
    holdOrder(cart, holdOrderId)
    reducer({
      type: sellReducerActions.CLEAR_CART
    })
  }
  
  const handleViewOrderOnHold = (orderId) => {
    const orderOnHold = selectHolderOnHold(orderId)
    
    reducer({
      cart: orderOnHold,
      holdOrderId: orderId,
      type: sellReducerActions.REFRESH_CART
    })
    
    setShowHoldOrders()
  }
  
  const getProceedButtonText = () => {
    if(getModifyOrderToLocalStorage()) {
      return "Proceed"
    }
    
    if(searchParams.get("pay_mode") === "invoice") {
      return "Proceed"
    }
    
    return "Proceed To Payment"
  }
  
  const handleProceedToPayment = () => {
    if (isAppStoreFront() && !userProfile) {
      onClose()
      onShowLogin()
      return
    }
    
    setCheckOutModal()
  }
  
  const handleOrderSuccess = () => {
    reducer({
      type: sellReducerActions.CLEAR_CART
    })
    navigate(-1)
  }
  
  const createOrderMutation = useCreateOrderMutation({successFn: handleOrderSuccess})
  
  const handleReturnAllOrder = () => {
    const deletedOrders = getModifyOrderToLocalStorage().map(order => ({
      ...order,
      action: "delete",
      return_type: "order",
      product: order.id,
      order_uuid: order.order_id,
      meta_measurement: order?.selected_unit_measurement || null
    }))
    
    createOrderMutation.mutate(deletedOrders)
  }
  
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useModal()
  const [customerMessage, setCustomerMessage] = useState("")
  const [customerSearchValue, setCustomerSearchValue] = useState("")
  const {
    isSuccess: isCustomerSuccess,
    data: allCustomers,
    isLoading: isCustomerLoading
  } = useCustomerQuery(customerSearchValue)
  const createCustomerMutation = useCreateCustomerMutation({successFn: setShowCreateCustomerModal})
  
  const CreateCustomer = () => (
    <label className="underline" onClick={setShowCreateCustomerModal}>Create +</label>
  )
  
  return (
    <CartStyled className="cart_section">
      {checkOutModal && <CheckOutModal onClose={setCheckOutModal} closeCart={onClose}/>}
      {addToCartModal && <AddToCartModal onClose={setAddToCartModal}/>}
      
      {showCreateCustomerModal && (
        <CustomerModal
          position="fixed"
          onClose={setShowCreateCustomerModal}
          mutation={createCustomerMutation}
          handleSubmit={createCustomerMutation.mutate}
        />
      )}
      
      <SpaceBetweenStyled>
        <Heading color="black" size={1.3}>Cart ({cart.length} Item{cart.length > 1 && "s"})</Heading>
        
        {window.location.origin !== env.STORE_FRONT_URL && (
          <Fragment>
            {isMobile() && (
              <Link to="/dashboard_sell">Back to sell page</Link>
            )}
            
            {getOrdersOnHold()?.length > 0 && (
              <Link to="#">
                <Heading color="black" size={1.1} onClick={setShowHoldOrders}>{showHoldOrders ? "Hide" : "Show"} orders
                  on hold</Heading>
              </Link>
            )}
          </Fragment>
        )}
        
        {window.location.origin === env.STORE_FRONT_URL && (
          <Fragment>
            <CloseSquare className="cart_icon" size={28} onClick={onClose}/>
          </Fragment>
        )}
      
      </SpaceBetweenStyled> <br/>
      
      <Form enableReinitialize
            values={{customer_name: selectedCustomer?.full_name || '', customer: selectedCustomer?.id || ''}}
            onSubmit={() => {
            }}>
        {!getModifyOrderToLocalStorage() && (
          <Select
            clearFilter
            className="fullWidth"
            isSearchLoading={isCustomerLoading}
            optional
            options={isCustomerSuccess ? allCustomers.results : []}
            displayKey="full_name"
            valueKey="id"
            name="customer"
            label="Customer"
            placeholder="Select customer"
            displayName="customer_name"
            uniqueKey="id"
            error={customerMessage}
            SideInfo={!isOffline && CreateCustomer}
            updateFn={(customer) => handleSelectCustomerForProductDiscount({
              cart, customer, reducer, currencyFormatter, setCustomerMessage
            })}
            updateQueryResult={setCustomerSearchValue}
          />
        )}
      </Form>
      
      <SectionStyled>
        {(showHoldOrders && getOrdersOnHold()?.length > 0) && (
          getOrdersOnHold().map(cart => (
            <HoldOrderCard
              setTrigger={setTrigger}
              orders={cart.orders}
              key={cart.key}
              id={cart.key}
              onClick={handleViewOrderOnHold}
            />
          ))
        )}
        
        {(cart.length < 1 && !showHoldOrders) && (
          <EmptyCartStyled>
            <Image width={35} src={EmptyBoxImage} alt="no product" />
            <Heading color="gray" size={1.2}>Cart is empty</Heading>
          </EmptyCartStyled>
        )}
  
        {(getOrdersOnHold().length < 1 && showHoldOrders) && (
          <EmptyCartStyled>
            <Image width={35} src={EmptyBoxImage} alt="no product" />
            <Heading color="gray" size={1.2}>No order on hold</Heading>
          </EmptyCartStyled>
        )}

        {(cart.length > 0 && !showHoldOrders) && (
          cart.map(product => (
              <CartProductCard
                key={product.unique_id}
                product={product}
                handleRemoveProductFromCart={handleRemoveProductFromCart}
                handleAddToCartWithModal={handleAddToCartWithModal}
              />
          ))
        )}
      </SectionStyled>

      <CartSummaryStyled>
        <SpaceBetweenStyled>
          <Heading color="secondary">Subtotal:</Heading>
          <Heading color="secondary">{subTotal}</Heading>
        </SpaceBetweenStyled>
  
        {!!totalServicesValue && (
          <SpaceBetweenStyled>
            <Heading color="secondary">Services:</Heading>
            <Heading color="secondary">{currencyFormatter(totalServicesValue)}</Heading>
          </SpaceBetweenStyled>
        )}
  
        {!!discountPercentage && (
          <SpaceBetweenStyled>
            <Heading color="secondary">Discount:</Heading>
            <Heading color="secondary">-{totalDiscount}</Heading>
          </SpaceBetweenStyled>
        )}
  
        {!!taxPercentage && (
          <SpaceBetweenStyled>
            <Heading color="secondary">Tax:</Heading>
            <Heading color="secondary">{totalTax}</Heading>
          </SpaceBetweenStyled>
        )}
  
        {!!checkoutDiscount && (
          <SpaceBetweenStyled>
            <Heading color="secondary">
              Checkout Discount{!!checkoutDiscountPercentage && ` (${checkoutDiscountPercentage}%)`}:
            </Heading>
            <Heading color="secondary">{currencyFormatter(checkoutDiscount)}</Heading>
          </SpaceBetweenStyled>
        )}
  
        <SpaceBetweenStyled>
          <Heading color="black" bold size={1.1}>Total:</Heading>
          <Heading color="black" bold size={1.1}>
            {overallCalculation}
          </Heading>
        </SpaceBetweenStyled>
  
        {businessSettings?.exchange_rate && (
          <SpaceBetweenStyled>
            <Paragraph color="black" bold className="section_title">Exchange Value: </Paragraph>
            <Paragraph color="black" bold>
              {currencyFormatterFn("en-NG", businessSettings?.ex_currency)(Number(overallCalculationValue) * Number(businessSettings?.currency_exchange_rate))}
            </Paragraph>
          </SpaceBetweenStyled>
        )}
  
        <FlexColumnStyled>
          <Button text={getProceedButtonText()} disabled={!(cart.length > 0)} onClick={handleProceedToPayment}/>
    
          {getModifyOrderToLocalStorage() && (
            <Button
              isLoading={createCustomerMutation.isLoading}
              text="Return All Order"
              bgColor="error"
              disabled={!(cart.length > 0)}
              onClick={handleReturnAllOrder}
            />
          )}
    
          {(!getModifyOrderToLocalStorage() && !isAppStoreFront()) && (
            <Button text="Hold Order" bgColor="black" disabled={!(cart.length > 0)} onClick={handleHoldOrder}/>
          )}
        </FlexColumnStyled>

      </CartSummaryStyled>
    </CartStyled>
  )
}

export default Cart


export const handleSelectCustomerForProductDiscount = ({
                                                         customer,
                                                         cart,
                                                         reducer,
                                                         setCustomerMessage,
                                                         currencyFormatter
                                                       }) => {
  reducer({
    customer,
    type: sellReducerActions.SET_CUSTOMER_FOR_ORDER
  })
  
  // if customer has discount available in the customer object, set customer discount on product
  if (customer?.customer_discount || customer?.customer_discount?.value) {
    const amount = customer?.customer_discount?.value_type === "percentage" ? `${customer?.customer_discount?.value}%` : currencyFormatter(customer?.customer_discount?.value)
    setCustomerMessage(`This customer has a ${amount} discount`)

    cart.forEach(product => {
      const newProduct = {
        ...product,
        isWithoutModal: false,
        updatedFromCartCard: true,
        selected_customer: customer,
        applied_discount: customer?.customer_discount,
        // store discount on the product in case you want to remove customer and fall back to original product discount
        previous_applied_discount: product?.previous_applied_discount || product?.applied_discount
      }
  
      reducer({
        product: newProduct,
        type: sellReducerActions.ADD_PRODUCT_TO_CART,
      })
    })
    return
  }
  
  setCustomerMessage("")
  cart.forEach(product => {
    const newProduct = {
      ...product,
      isWithoutModal: false,
      updatedFromCartCard: true,
      selected_customer: (!!customer?.id ? customer : {}),
      // use product discount if previous customer had discount or just use the original product discount
      applied_discount: product?.previous_applied_discount || product?.applied_discount,
    }
  
    reducer({
      product: newProduct,
      type: sellReducerActions.ADD_PRODUCT_TO_CART,
    })
  })
}