import {isAllKeysValueEmpty} from "../../../utils/handleQueryParams";

const useOrderQueryWithSearchAndFilter = (state, useMainQuery, noPagination = false) => {
	const queryValues = { ...(!!state?.filterValue && { status: state.filterValue }), ...state }
	
	const searchResponse = useMainQuery(queryValues)
	const searchQuery = queryResponse(searchResponse)
	const data = searchQuery?.data

	return {
		mainQuery: searchQuery,
		searchQuery,
		get data() { return data },
		get next() { return data?.next },
		get prev() { return data?.previous },
		get currentCount() { return handleGetCurrentCount(data?.next, data?.previous) },
		get showPagination() { return !!data?.next || !!data?.previous },
		get isQueryWithData() {
			return (searchQuery.isSuccess && !getIsSearchOrFilterEmpty(data, noPagination))
		},
		refetch: () => {
			return searchQuery.refetch()
		},
		get isQueryLoading() { return searchQuery.isLoading },
		get isQueryWithNoData() {
			return (searchQuery.isSuccess && getIsSearchOrFilterEmpty(data, noPagination))
		},
		get isEmpty() { return noPageData(searchQuery, data) && !this.isQueryLoading && isAllKeysValueEmpty(queryValues) },
		emptyMessage(dataName) { return getEmptyMessage(dataName, state) },
		
		get showTable() {
			if(searchQuery.isLoading) return true
			if(searchQuery.isSuccess && !this.isEmpty) return true
		},
		
		get queryValues() { return queryValues }
	}
}

export default useOrderQueryWithSearchAndFilter


const queryResponse = (query) => {
	return {
		data: query?.data,
		isSuccess: query?.isSuccess,
		isLoading: query?.isLoading,
		refetch: query?.refetch
	}
}

export const handleGetCurrentCount = (next, previous) => {
	if (!next && !previous) return 1
	
	// handle count when on the last pagination
	if (!next) {
		const params = new URLSearchParams(previous)
		if (params.has("limit") && params.has("offset")) {
			return (Number(params.get("offset")) / Number(params.get("limit"))) + 2
		}
		
		for (const p of params) {
			const urlWithOutParams = p[0].split("?")[0]
			if (p[0] === "page" || p[0] === `${urlWithOutParams}?page`) {
				return Number(p[1]) + 1
			}
		}
	}
	
	const params = new URLSearchParams(next)
	
	if (params.has("limit") && params.has("offset")) {
		return Number(params.get("offset")) / Number(params.get("limit"))
	}
	
	for (const p of params) {
		const urlWithOutParams = p[0].split("?")[0]
		if (p[0] === "page" || p[0] === `${urlWithOutParams}?page`) {
			return Number(p[1] - 1)
		}
	}
}

const getEmptyMessage = (dataName, state) => {
	const { searchValue, filterValue } = state

	if(!!filterValue && !searchValue) {
		return `No ${dataName} with that filter`
	}
	
	if(!!searchValue) {
		return `No ${dataName} with Id "${searchValue}"`
	}
	
	return `No ${dataName}`
}

const getIsSearchOrFilterEmpty = (data, noPagination) => {
	if(noPagination) {
		return (!data || data.length < 1)
	}
	
	return (!data || data.results.length < 1)
}

const noPageData = (mainQuery, noPagination) => {
	if(noPagination) {
		return (mainQuery?.data?.length < 1)
	}
	
	if(mainQuery?.data?.results?.length < 1) {
		return true
	}
	// return (!data || data?.results?.length < 1 || data?.length < 1)
}