import {useQueryClient} from "react-query";
import queryKeys from "../../useServices/queryKeys";
import {getTableService} from "../../../services/tableServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getGroupOrderByBusinessIdAndTableTrackIdService} from "../../../services/orderServices";
import {globalReducerActions} from "../../../reducers/globalReducer";

const useTablePrefetchMutation = () => {
	const { globalState: { tableId }, globalReducer, getBusinessId, navigate } = useGlobalContext()
	const queryClient = useQueryClient()
	const businessId = getBusinessId()

	const fetch = (tableTrackIdProps) => {
		queryClient.prefetchQuery({
			queryKey: [queryKeys.TABLES, undefined],
			queryFn: () =>  getTableService(businessId)
		})

		const table_track_id = tableId || tableTrackIdProps

		if(!!table_track_id) {
			queryClient.prefetchQuery(
				`${queryKeys.GROUP_ORDERS}_${businessId}_${table_track_id}`,
				getGroupOrderByBusinessIdAndTableTrackIdService.bind(this, businessId, table_track_id)
			)

			globalReducer({
				type: globalReducerActions.CLEAR_TABLE_DATA
			})

			navigate("/dashboard/table/")
		}
	}

	return { fetch }
}

export default useTablePrefetchMutation