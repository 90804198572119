import axios from "../../axios";

const getTableService = async (businessId, searchValue = " ") => {
	try {
		const { data } = await axios.get(`/tables/?business_id=${businessId}&search=${searchValue}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const createTableService = async (body) => {
	try {
		const { data } = await axios.post(`/tables/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const editTableService = async (tableId, body) => {
	try {
		const { data } = await axios.patch(`/tables/${tableId}`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const deleteTableService = async (tableId) => {
	try {
		const { data } = await axios.delete(`/tables/${tableId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const clearTableService = async (tableId) => {
	try {
		const { data } = await axios.patch(`/tables/${tableId}`, { status: "available" })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export {
	getTableService, createTableService, editTableService,
	deleteTableService, clearTableService
}