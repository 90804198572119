import {useDiscountQuery} from "../../useServices/useDiscountServices";

const useDiscountCalculation = () => {
  const { data: allDiscounts } = useDiscountQuery()

  const activeDiscount = allDiscounts?.results.find(discount => discount.active === true)

  return activeDiscount?.value || 0
}

export default useDiscountCalculation