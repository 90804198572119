import {TableOrderSummaryStyled} from "../../../styles/drawerStyles";
import {SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading} from "../../../styles/textStyles";
import {Button} from "../../index";
import useGroupOrderCalculation from "../../../hooks/useUtils/useGroupOrderCalculation";


const TableOrderSummary = (
	{ cart, onClick, buttonText, checkOut }
) => {
	const {
		taxPercentage,
		discountPercentage, totalDiscount, totalCost, totalTax, subTotal
	} = useGroupOrderCalculation(cart)


	return (
		<TableOrderSummaryStyled>
			<SpaceBetweenStyled>
				<Heading color="secondary">Subtotal:</Heading>
				<Heading color="secondary">{subTotal}</Heading>
			</SpaceBetweenStyled>

			{!!discountPercentage && (
				<SpaceBetweenStyled>
					<Heading color="secondary">Discount: {discountPercentage}%</Heading>
					<Heading color="secondary">
						{totalDiscount}
					</Heading>
				</SpaceBetweenStyled>
			)}

			{!!taxPercentage && (
				<SpaceBetweenStyled>
					<Heading color="secondary">Tax: {taxPercentage}%</Heading>
					<Heading color="secondary">
						{totalTax}
					</Heading>
				</SpaceBetweenStyled>
			)}

			<SpaceBetweenStyled>
				<Heading color="black" bold size={1.1}>Total:</Heading>
				<Heading color="black" bold size={1.1}>
					{totalCost}
				</Heading>
			</SpaceBetweenStyled>

			{checkOut && (
				<label className="submit_checkout" htmlFor="submit_checkout">
					CheckOut
				</label>
			)}

			{!checkOut && <Button text={buttonText} onClick={onClick} />}
		</TableOrderSummaryStyled>
	)
}

export default TableOrderSummary