import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {navMenuLists, setupNavMenu} from "../../../data/dashboardData";
import {useUserProfile} from "../../useServices/userHooks";
import {actionsPermissions, routesPermissions} from "../../../data/permissions";
import {isPackageExpired} from "../../../utils/expiryCheck";
import env from "../../../utils/env";


const useRoutePermission = () => {
	const navigate = useNavigate()
	const navigations = navMenuLists
	const { pathname } = useLocation()

	const { isSuccess: isUserProfileAvailable, data: userProfile } = useUserProfile()
	const { user_permission = [], id, user_type } = userProfile?.[0] || {}
	const flattenNavigationRoutes = []

	for (const navItem of navigations) {
		navItem?.options?.forEach(optionItem => { flattenNavigationRoutes.push(optionItem) })
	}

	const currentRoute = [...navigations, ...flattenNavigationRoutes, ...setupNavMenu].find(item => item.link === pathname)

	const isStaffDashboard = () => {
		const pathInArr = pathname.split("/")
		const staffId = pathInArr[pathInArr.length - 1] === "/" ? pathInArr[pathInArr.length - 2] : pathInArr[pathInArr.length - 2]
		return id === parseInt(staffId)
	}
	
	const handleSubCategoryPermission = () => {
		const pathArr = pathname.split("/")
		if (pathArr[3] !== "categories") return false
		
		return user_permission.includes(routesPermissions.viewListOfCategories)
	}
	
	const handleCreateInvoicePermission = () => {
		const pathArr = pathname.split("/")
		if (!pathArr.includes("dashboard_sell")) return false
		
		return user_permission.includes(actionsPermissions.createInvoice)
	}
	
	const handleCartPermission = () => {
		const pathArr = pathname.split("/")
		if (pathArr[pathArr.length - 1] !== "cart") return false
		
		return user_permission.includes(routesPermissions.viewSell)
	}
	
	const handleProductPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[pathArr.length - 2] !== "product") return false
		
		return user_permission.includes(routesPermissions.viewListOfProducts)
	}
	
	const handleViewOrderDetailsPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[pathArr.length - 2] !== "orders") return false
		
		return user_permission.includes(routesPermissions.viewListOfSales)
	}
	
	const handleViewProductionProcessPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[pathArr.length - 2] !== "process") return false
		
		return user_permission.includes(routesPermissions.viewProductionProcess)
	}
	
	const handleViewCustomerDetailsPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[pathArr.length - 2] !== "customer") return false
		
		return user_permission.includes(routesPermissions.viewListOfCustomers)
	}
	
	const handleStagesPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[3] !== "stages") return false
		
		return user_permission.includes(routesPermissions.viewListOfServices)
	}

	const handleSettingsPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[2] === "settings") return true
	}
	
	const handleSelectStore = () => {
		if(pathname === "/select-store/" || pathname === "/select-store" || pathname === "/on-boarding/setup-store/") return true
	}

	const handlePackageExpired = () => {
		if(handleSelectStore()) return

		const isClientAdmin = userProfile?.[0]?.user_type === "client_admin"

		if(pathname === "/dashboard/settings/subscription" && isClientAdmin) return

		navigate("/dashboard/package_expired")
	}

	const handleViewCustomerSubscriptionPurchaseDetailsPermission = () => {
		const pathArr = pathname.split("/")
		if(pathArr[2] !== "purchased_subscriptions") return false

		return user_permission.includes(routesPermissions.viewCustomerSubscriptionHistory)
	}

	const handleStaffViewOfOtherStaffDetailsPagePermission = () => {
		const pathArr = pathname.split("/")

		if(pathArr[2] === "staff" && Number(pathArr[3]) > 0) {
			return user_permission.includes(routesPermissions.viewListOfStaffs)
		}

		return false
	}
	
	const handleViewOfPurchaseOrderDetailsPagePermission = () => {
		const pathArr = pathname.split("/")

		if(pathArr[3] === "purchase_order" && !!pathArr[4]) {
			return user_permission.includes(routesPermissions.viewListOfPurchaseOrder)
		}
		
		return false
	}
	
	const handleStaffViewDebtorDetailsPagePermission = () => {
		const pathArr = pathname.split("/")

		if(pathArr[3] === "debtor" && Number(pathArr[4]) > 0) {
			return user_permission.includes(routesPermissions.viewListOfDebtors)
		}
		
		return false
	}

	useEffect(() => {
		if(isPackageExpired(userProfile?.[0]?.package_details)) {
			handlePackageExpired()
			return
		}
		
		if(isUserProfileAvailable && userProfile?.length > 0) {
			if(pathname === "/login" || pathname === "/login/") {
				return
			}
			
			if(window.location.origin === env.STORE_FRONT_URL) {
				return
			}
			
			if(
				user_type === "staff" ||
				user_type === "client_admin" ||
				[...user_permission, routesPermissions.openRoute].includes(currentRoute?.permission) ||
				isStaffDashboard() ||
				handleSelectStore() ||
				handleCreateInvoicePermission() ||
				handleSubCategoryPermission() ||
				handleStagesPermission() ||
				handleCartPermission() ||
				handleSettingsPermission() ||
				handleProductPermission() ||
				handleViewOrderDetailsPermission() ||
				handleViewCustomerDetailsPermission() ||
				handleViewOfPurchaseOrderDetailsPagePermission() ||
				handleViewCustomerSubscriptionPurchaseDetailsPermission() ||
				handleStaffViewOfOtherStaffDetailsPagePermission() ||
				handleStaffViewDebtorDetailsPagePermission() ||
				handleViewProductionProcessPermission()
			) {

			} else {
				navigate(`/dashboard/staff_dashboard`)
			}
		}
	}, [pathname, userProfile])

	return ""
}


export default useRoutePermission