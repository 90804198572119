import {getBusinessIdFromStorage} from "../index";

const DESKTOP_SYNC_STORAGE_KEY = "OVALOOP_DESKTOP_SYNC_STORAGE"

export const formatDatetimeForBackend = (dateValue) => {
  const date = new Date(dateValue)
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const syncRequestKeyEnum = {
  USER: 'USER',
  PRODUCT: 'PRODUCT',
  CUSTOMER: 'CUSTOMER',
  BUSINESS: 'BUSINESS',
  GROUP_ORDER: 'GROUP_ORDER',
  SINGLE_ORDER: 'SINGLE_ORDER',
  TAXES: "TAXES",
  SUPPLIERS: "SUPPLIERS",
  DISCOUNTS: "DISCOUNTS",
  NESTED_SUB_CATEGORY: "NESTED_SUB_CATEGORY",
  BANKS: "BANKS",
  SUB_CATEGORIES: "SUB_CATEGORIES",
  CATEGORIES: "CATEGORIES",
  ADDONS: "ADDONS",
  STAGES: "STAGES"
}

export const setSyncStatusForOffline = (syncKey) => {
  const dataInStorage = localStorage.getItem("syncStatusForOffline")
  
  if (dataInStorage) {
    localStorage.setItem("syncStatusForOffline", JSON.stringify({
      ...JSON.parse(dataInStorage),
      [syncKey]: true
    }))
    return
  }
  
  localStorage.setItem("syncStatusForOffline", JSON.stringify({
    [syncKey]: true
  }))
}

export const getSyncStatusForOffline = (syncKey) => {
  const dataInStorage = localStorage.getItem("syncStatusForOffline")
  if (!dataInStorage) return false
  
  const data = JSON.parse(dataInStorage)
  return !!data[syncKey]
}

/**
 * @param status{Boolean}
 * @param businessId{String}
 * */
export const setBusinessIsReadyForOffline = (status, businessId) => {
  localStorage.setItem(`business_ready_for_offline_${businessId}`, status.toString())
}

export const isAllDesktopRequestSynced = () => {
  const businessId = getBusinessIdFromStorage()
  return localStorage.getItem(`business_ready_for_offline_${businessId}`) === "true"
}

export const clearAllDesktopBusinessRequestSynced = () => {
  const allDataInLocalStorage = {...localStorage}
  
  Object.keys(allDataInLocalStorage).forEach(key => {
    if (key.includes("business_ready_for_offline")) {
      localStorage.removeItem(key)
    }
  })
  
  localStorage.removeItem("syncStatusForOffline")
  window.dispatchEvent(new Event("all_business_sync_cleared"))
}


export const clearDesktopSyncStorage = () => {
  localStorage.removeItem(DESKTOP_SYNC_STORAGE_KEY)
  localStorage.removeItem("CURRENT_SYNCED_BUSINESS_ID")
  localStorage.removeItem(`OVALOOP_UPDATED_LAST_TIME_FOR_SYNC_${syncRequestKeyEnum.PRODUCT}`)
  localStorage.removeItem(`OVALOOP_UPDATED_LAST_TIME_FOR_SYNC_${syncRequestKeyEnum.CUSTOMER}`)
}