import {BiError} from "react-icons/bi";
import {Button} from "../../index";
import {Heading} from "../../../styles/textStyles";
import {FlexColumnStyled, ScreenLoadingStyled} from "../../../styles/utilStyles";


const ScreenLoading = ({ refetchFn }) => {
	const refetchQuery = () => {
		if(refetchFn) {
			refetchFn()
			return
		}
		
		window.location.reload()
	}
	
	return (
		<ScreenLoadingStyled>
			<FlexColumnStyled>
				<BiError size={120} />
				<Heading size={1.5} color="black">Something went wrong, please try again</Heading>
				
				<Button text="Retry" onClick={refetchQuery} />
			</FlexColumnStyled>
		</ScreenLoadingStyled>
	)
}


export default ScreenLoading