import {IPC_EVENTS} from "../../../shared/ipcEvents";
import {getBusinessAccountIdFromStorage, getBusinessIdFromStorage, getUserIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import {useQuery} from "react-query";
import useDesktopOfflineModeAllowed from "../useUtils/useDesktopOfflineModeAllowed";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import useGlobalContext from "../../useContexts/useGlobalContext";


const main = [getBusinessIdFromStorage(), "desktop offline"]
export const desktopOfflineQueryKeys = {
  all: main,
  user: () => [...main, "user"],
  allBusiness: () => [...main, "all business"],
  business: () => [...main, "business"],
  allProduct: [...main, "products"],
  allCustomer: [...main, "customers"],
  isOfflineReady: [...main, "offline mode allowed"],
  discount: (query = {}) => [...main, "discount", getAllObjectKeysWithValues(query)],
  tax: (query = {}) => [...main, "tax", getAllObjectKeysWithValues(query)],
  supplier: (query = {}) => [...main, "tax", getAllObjectKeysWithValues(query)],
  bank: (query = {}) => [...main, "bank", getAllObjectKeysWithValues(query)],
  addons: (query = {}) => [...main, "addons", getAllObjectKeysWithValues(query)],
  addonStages: (query = {}) => [...main, "addon stages", getAllObjectKeysWithValues(query)],
  singleCustomers: (query = {}) => [...main, "single customers", getAllObjectKeysWithValues(query)],
  customers: (query = {}) => [...main, "customers", getAllObjectKeysWithValues(query)],
  products: (query = {}) => [...main, "products", getAllObjectKeysWithValues(query)],
  categories: (query = {}) => [...main, "categories", getAllObjectKeysWithValues(query)],
  subCategories: (query = {}) => [...main, "sub categories", getAllObjectKeysWithValues(query)],
  groupOrders: (query = {}) => [...main, "group orders", getAllObjectKeysWithValues(query)],
  offlineGroupOrders: (query = {}) => [...main, "offline group orders", getAllObjectKeysWithValues(query)],
  singleOrders: (query = {}) => [...main, "single orders", getAllObjectKeysWithValues(query)]
}

export const getDataFromDBService = async (ipcEventName, query = {}) => {
  if (!window?.require) return
  const {ipcRenderer} = window.require("electron")
  
  return new Promise((resolve, reject) => {
    ipcRenderer.once(ipcEventName, (_, data) => {
      resolve(data);
    });
    
    ipcRenderer.once(IPC_EVENTS.logDatabaseFetchError(ipcEventName), (_, data) => {
      reject({error: data, event: ipcEventName})
    })
    
    ipcRenderer.send(ipcEventName, getAllObjectKeysWithValues({
      ...query,
      user_id: getUserIdFromStorage(),
      business_id: getBusinessIdFromStorage(),
      account_id: getBusinessAccountIdFromStorage()
    }));
  })
}

export const useDesktopDiscountsServiceFromLocalDB = (search) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.discount({search})
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_DISCOUNTS, {search}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopBusinessSyncStatusFromLocalDB = () => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  return useQuery({
    enabled: (isDesktopOfflineModeAllowed && !!getBusinessIdFromStorage()),
    queryKey: desktopOfflineQueryKeys.isOfflineReady,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.IS_OFFLINE_READY)
  })
}

export const useDesktopTaxServiceFromLocalDB = (search) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.tax({search})
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_TAXES, {search}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopSupplierServiceFromLocalDB = (search) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.supplier({search})
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_SUPPLIERS, {search}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopBanksServiceFromLocalDB = (search) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.bank({search})
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_BANKS, {search}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopAddonsServiceFromLocalDB = (search) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.addons({search})
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_ADDONS, {search}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopAddonStagesServiceFromLocalDB = (query = {}) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.addonStages(query)
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_ADDON_STAGES, query),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    })
  })
}

export const useDesktopCustomersServiceFromLocalDB = (query = {}) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  let queryKey = desktopOfflineQueryKeys.customers(query)

  if(!!query.id) {
    queryKey = desktopOfflineQueryKeys.singleCustomers(query)
  }
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_CUSTOMERS, query),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopProductsServiceFromLocalDB = (query = {}) => {
  const errorLogger = useErrorLogger()
  const queryKey = desktopOfflineQueryKeys.products(query)
  
  return useQuery({
    enabled: !!window?.require,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_PRODUCTS, query),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopCategoriesServiceFromLocalDB = (search) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.categories({search})
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey: queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_CATEGORY, {search}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopSubCategoriesServiceFromLocalDB = (query = {}) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.subCategories(query)
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_SUB_CATEGORY, query),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    })
  })
}

export const useDesktopGroupOrdersServiceFromLocalDB = (query = {}) => {
  const errorLogger = useErrorLogger()
  const queryKey = desktopOfflineQueryKeys.groupOrders(query)
  
  return useQuery({
    enabled: !!window?.require,
    queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_GROUP_ORDERS, query),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    })
  })
}

export const useDesktopSingleProductServiceFromLocalDB = (product_id) => {
  const {toast, navigate} = useGlobalContext()
  const errorLogger = useErrorLogger()
  const queryKey = desktopOfflineQueryKeys.products({product_id})
  
  return useQuery({
    enabled: !!window?.require,
    queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_SINGLE_PRODUCT, {product_id}),
    onError: ({error, event}) => {
      errorLogger.create({
        error, queryKey, event,
        errorType: errorTypes.ipcGetDataFromDesktopDatabase
      })
      toast.error(error, error)
      navigate(-1)
    }
  })
}

export const useDesktopOnlyOfflineGroupOrdersServiceFromLocalDB = (query = {}) => {
  const errorLogger = useErrorLogger()
  const queryKey = desktopOfflineQueryKeys.offlineGroupOrders(query)
  
  return useQuery({
    enabled: !!window?.require,
    queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_OFFLINE_GROUP_ORDERS, query),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopSingleOrdersServiceFromLocalDB = (group_id) => {
  const errorLogger = useErrorLogger()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const queryKey = desktopOfflineQueryKeys.singleOrders({group_id})
  
  return useQuery({
    enabled: isDesktopOfflineModeAllowed,
    queryKey,
    retry: 3,
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_SINGLE_GROUP_ORDERS, {group_id}),
    onError: ({error, event}) => errorLogger.create({
      error, queryKey, event,
      errorType: errorTypes.ipcGetDataFromDesktopDatabase
    }),
  })
}

export const useDesktopUserServiceFromLocalDB = () => {
  return useQuery({
    retry: 3,
    enabled: (!!window?.require && !!getUserIdFromStorage()),
    queryKey: desktopOfflineQueryKeys.user(),
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_USER)
  })
}

export const useDesktopBusinessServiceFromLocalDB = () => {
  return useQuery({
    retry: 3,
    enabled: (!!window?.require && !!getBusinessIdFromStorage()),
    queryKey: desktopOfflineQueryKeys.business(),
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_BUSINESS)
  })
}

export const useDesktopAllBusinessServiceFromLocalDB = () => {
  return useQuery({
    retry: 3,
    enabled: (!!window?.require && !!getUserIdFromStorage()),
    queryKey: desktopOfflineQueryKeys.allBusiness(),
    queryFn: () => getDataFromDBService(IPC_EVENTS.GET_ALL_BUSINESS, {all_business: true})
  })
}