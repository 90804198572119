export const getOrderServiceStructure = (data) => {
	if(!data?.results) {
		return data.map(item => {
			return {
				...item,
				total_amount_paid: getTotalAmountPaid(item),
				customer_name: item.customer_name || "Guest",
				cost_before_tax: Number(item.total_amount) - Number(item.tax_amount),
				cost_after_tax: Number(item.total_amount),
			}
		})
	}
	
	
	const results = data.results.map(item => {
		return {
			...item,
			total_amount_paid: getTotalAmountPaid(item),
			customer_name: item.customer_name || "Guest",
			cost_before_tax: Number(item.total_amount) - Number(item.tax_amount),
			cost_after_tax: Number(item.total_amount),
		}
	})
	
	return { ...data, results }
}


export const getRecentSingleOrderStructure = (data) => {
	return data.map(item => {
		return {
			...item,
			status: "payment_confirmed",
			total_items: item.qty,
			total_amount_paid: getTotalAmountPaid(item),
			customer_name: item.customer_name || "Guest",
			cost_before_tax: Number(item.total_cost) - Number(item.tax_amount),
			cost_after_tax: Number(item.total_cost),
		}
	})
}


const getTotalAmountPaid = (data) => {
	return data?.split_payment?.reduce((prev, curr) => {
		return prev + Number(curr.amount)
	}, 0)
}