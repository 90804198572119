import styled from "styled-components";

const Heading2 = styled("h2")`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-family: Light, serif;
  line-height: ${props => props.spread ? "40px" : "35px"};
  color: ${props => props.color ? props.theme[props.color] : "white"};
`

const Heading = styled("h2")`
  color: ${props => props.theme[props.color] || "white"};
  font-size: ${props => props.size ? `${props.size}rem !important` : "1rem !important"};
  font-family: ${props => props.bold ? "Medium, serif" : props.thin ? "Thin, serif" : "Light, serif" };
`

const Heading4 = styled("h4")`
  font-size: 1rem;
  font-family: Light, serif;
  line-height: ${props => props.spread ? "40px" : "35px"};
  color: ${props => props.color ? props.theme[props.color] : "white"};
`

const Text = styled("h3")`
  font-size: 1.4rem;
  margin-bottom: .3rem;
  font-family: Light, serif;
`

const Paragraph = styled("p")`
  text-decoration: ${props => props.$strike && "line-through"};
  margin-top: ${props => !props.noTop && ".2rem" };
  color: ${props => props.theme[props.color] || "white"};
  font-size: ${props => props.size ? `${props.size}rem !important` : "1rem !important"};
  font-family: ${props => props.bold ? "Medium, serif" : "Light, serif" };
`

export {
  Heading2,
  Heading4,
  Text,
  Heading,
  Paragraph
}